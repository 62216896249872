import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const search = (req: any) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/menus/search?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getMenuById = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/menus/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const deleteMenu = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .delete(`admin/menus/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createMenu = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .post("admin/menus", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateMenu = (req: { params: { id: string }; body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .put(`admin/menus/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getAll = () =>
  new Promise((resolve, reject) => {
    request()
      .get("admin/menus")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const ordering = (req: { body: { id: string; order: number }[] }) =>
  new Promise((resolve, reject) => {
    request()
      .put("admin/menus/ordering/sort", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getTreeMenu = ({ query }: { query?: any }) =>
  new Promise((rs, rj) => {
    const queryParams = qs.stringify(query);
    request()
      .get(`admin/menus/tree?${queryParams}`)
      .then((res) => {
        const { data } = res.data;
        if (data) rs(data);
        else {
          rj(new Error("Something went error"));
        }
      })
      .catch(parseErrorResponse)
      .then(rj);
  });

const MenuServices = {
  search,
  getMenuById,
  deleteMenu,
  createMenu,
  updateMenu,
  getAll,
  ordering,
  getTreeMenu,
};

export default MenuServices;
