/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import { FileUpload } from "primereact/fileupload";
import UploadService from "services/uploads";
import { Button, Image, ProgressBar, Toast } from "primereact";
import { showToast } from "utils/common";
import { InputText } from "primereact/inputtext";

const View = (props: {
  urlFile?: string;
  setUrlFile?: any;
  setFile?: any;
  label?: string;
  isShowInit?: boolean;
  fileType?: "image" | "video" | "audio" | ".pdf";
  subFileType?: string;
  imageStyle?: any;
  setObjectFile?: any;
  objectFile?: any;
  isObject?: boolean;
}) => {
  const {
    urlFile,
    setUrlFile,
    setFile,
    label,
    isShowInit = true,
    fileType = "image",
    subFileType = "*",
    imageStyle = {},
    isObject = false,
    objectFile = {},
    setObjectFile,
  } = props;
  const toast = useRef(null);
  const [isShow, setIsShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const myUploader = async (event) => {
    try {
      setLoading(true);
      let res = null;
      const file = event.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        res = await UploadService.uploadFile({ body: formData });
      }
      if (res && res.filePath) {
        if (setFile) setFile(res);
        if (setUrlFile) setUrlFile(res.filePath);
        if (setObjectFile)
          setObjectFile({
            alt: objectFile?.alt,
            src: res.filePath,
          });
        setLoading(false);
        setIsShow(false);
      }
    } catch (error) {
      showToast(error);
      setLoading(false);
    }
  };

  const onUpload = (data) => {
    console.log({ data });
  };

  const chooseOptions = { label: "Choose", icon: "pi pi-fw pi-plus" };
  const uploadOptions = {
    label: "Upload",
    icon: "pi pi-upload",
    className: "p-button-success",
  };
  const cancelOptions = {
    label: "Cancel",
    icon: "pi pi-times",
    className: "p-button-danger",
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <div>
          {chooseButton}
          {uploadButton}
          {cancelButton}
        </div>
        <div className="absolute right-0 ">
          <Button
            className="w-4rem p-button-secondary"
            icon="bx bx-arrow-back"
            onClick={(_) => setIsShow(false)}
          />
        </div>
      </div>
    );
  };

  const renderFile = () => {
    switch (fileType) {
      case "image":
        return (
          <Image
            src={isObject ? objectFile.src : urlFile}
            width={"100"}
            height="100"
            preview
            imageStyle={imageStyle}
          />
        );
      case ".pdf":
        return <p>{urlFile}</p>;
      case "video":
        return <video src={urlFile} width={100} height={100} />;
      default:
        return null;
    }
  };

  return (
    <div className="">
      <Toast ref={toast} />
      <div className="text-center mb-2">{label}</div>
      {!isShow ? (
        <div className="flex flex-column align-items-center">
          {(isShowInit || urlFile || objectFile.src) && renderFile()}
          <div>
            {" "}
            <Button
              icon="pi pi-upload"
              className="p-button-sm p-button-success"
              aria-label="Bookmark"
              onClick={(_) => setIsShow(true)}
            />{" "}
            <Button
              icon="pi pi-times"
              className="p-button-sm p-button-danger"
              aria-label="Delete"
              onClick={(_) => {
                if (setUrlFile) setUrlFile(null);
                if (setFile) setFile({});
              }}
            />
          </div>
        </div>
      ) : (
        <div className="field ">
          {isObject && (
            <div className="w-full mb-1">
              <InputText
                className="w-full mb-1"
                value={objectFile.alt}
                onChange={(e) =>
                  setObjectFile({
                    src: objectFile.src,
                    alt: e.target.value,
                  })
                }
                placeholder="Alt"
              />
              <InputText
                className="w-full"
                value={objectFile.src}
                onChange={(e) =>
                  setObjectFile({
                    alt: objectFile.alt,
                    src: e.target.value,
                  })
                }
                placeholder="Src"
              />
            </div>
          )}
          <FileUpload
            chooseOptions={chooseOptions}
            uploadOptions={uploadOptions}
            cancelOptions={cancelOptions}
            headerTemplate={headerTemplate}
            customUpload={true}
            progressBarTemplate={
              loading && <ProgressBar mode="indeterminate" />
            }
            mode={"advanced"}
            uploadHandler={myUploader}
            onUpload={onUpload}
            multiple={false}
            accept={
              fileType === ".pdf" ? fileType : `${fileType}/${subFileType}`
            }
            maxFileSize={fileType === "image" ? 10000000 : 100000000}
            auto={false}
            emptyTemplate={
              <p className="m-0">Drag and drop files to here to upload.</p>
            }
          />
        </div>
      )}
    </div>
  );
};

export default View;
