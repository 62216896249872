import { forwardRef, MutableRefObject, useRef } from "react";
import Selecto from "react-selecto";
import { SeatMapRow } from "types/seats-map";
type Props = {
  seats: SeatMapRow;
  handleSelect: (selected: string[]) => void;
};

const Selector = (
  { seats, handleSelect }: Props,
  scrollerRef: MutableRefObject<HTMLDivElement>
) => {
  const selectoRef = useRef<Selecto>(null);
  return (
    <Selecto
      ref={selectoRef}
      preventDefault={true}
      dragContainer={document.getElementById(`select-container`)}
      selectableTargets={["#selecto1 div div .seat"]}
      onSelectEnd={(e) => {
        if (e.isClick && !e.selected.length) return;
        const { afterAdded, beforeSelected } = e;

        const afterAddedIds = afterAdded?.map((item) => item.id);
        const beforeSelectedIds = beforeSelected?.map((item) => item?.id);
        const selected = [...afterAddedIds, ...beforeSelectedIds];

        const filterSelected = selected.filter(
          (item) =>
            seats.flat().find((seat) => seat.id === item && !seat.isDisabled)?.id
        );

        handleSelect(filterSelected);
      }}
      onScroll={({ direction }) => {
        scrollerRef.current!.scrollBy(direction[0] * 10, direction[1] * 10);
      }}
      onInnerScroll={({ container, direction }) => {
        container.scrollBy(direction[0] * 10, direction[1] * 10);
      }}
      checkInput={true}
      scrollOptions={{
        container: scrollerRef,
        getScrollPosition: () => {
          return [
            scrollerRef.current!.scrollLeft,
            scrollerRef.current!.scrollTop,
          ];
        },
        throttleTime: 30,
        threshold: 0,
      }}
      innerScrollOptions={true}
      hitRate={0}
      selectByClick={true}
      selectFromInside={true}
      toggleContinueSelect={["shift"]}
      ratio={0}
    />
  );
};

export default forwardRef(Selector);
