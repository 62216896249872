/* eslint-disable react-hooks/exhaustive-deps */
import VConfirm from "components/v-confirm";
import VDialog from "components/v-dialog";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import BlogServices from "services/blogs";
import { formatLocalTime, showToast } from "utils/common";
import Details from "./components/Details";
import SettingService from "services/settings";
import { SETTING_TYPES } from "utils/enum";

const EmailTemplates = () => {
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState(null);

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);

  const refDetail = useRef(null);

  const loadData = async () => {
    try {
      const res: any = await SettingService.getSettingsByType({
        type: SETTING_TYPES.EMAIL_TEMPLATES,
      });
      if (res) {
        console.log({ res });
        // setData(res.setting);
      } else setData([]);
    } catch (error) {
      setData([]);
    }
  };

  //CREATE OR NEW
  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };
  const editProduct = (data) => {
    setDetail({ ...data });
    refDialogDetail.current.show();
  };
  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };
  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };
  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  // DELETE
  const confirmDelete = (product) => {
    setDetail(product);
    refDialogDelete.current.show();
  };

  const handleDelete = async () => {
    try {
      await BlogServices.deleteById({
        params: {
          id: detail._id,
        },
      });
      const _data = data.filter((val) => val._id !== detail._id);
      setData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "Seat Map Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  //RENDER
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  };
  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning mr-2"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  return (
    <div className="grid View-demo">
      <div className="col-12">
        <div className="card">
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={data}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            //panigate
            paginator
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            //sort
          >
            <Column
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "120px" }}
            ></Column>
            <Column
              field="name"
              header="Name"
              style={{ flexGrow: 1, flexBasis: "120px" }}
            ></Column>
            <Column
              field="template"
              header="Template"
              style={{ flexGrow: 1, flexBasis: "120px" }}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "160px" }}
              field="status"
              header="Status"
              body={(rowData) => (
                <span
                  className={`v-badge status-${rowData?.status?.toLowerCase()}`}
                >
                  {rowData?.status}
                </span>
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "100px" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
          </DataTable>

          <VDialog
            ref={refDialogDetail}
            header="Details"
            onSubmit={onSumitDialogDetail}
            closeOnEscape={false}
          >
            <Details
              ref={refDetail}
              data={detail}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(EmailTemplates, comparisonFn);
