/* eslint-disable react-hooks/exhaustive-deps */
import { InputText } from "primereact/inputtext";
import {
  forwardRef,
  ReactNode,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { MasterContext } from "contexts/MasterContext";
import SettingService from "services/settings";
import { showToast } from "utils/common";
import { v4 as uuidv4 } from "uuid";
import { InputTextarea } from "primereact/inputtextarea";

const Row = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={`flex flex-column w-full ${className}`}
      style={{
        rowGap: 12,
      }}
    >
      {children}
    </div>
  );
};

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const { auth } = useContext(MasterContext);

  const [systemForm, setSystemForm] = useState({
    id: uuidv4(),
    name: "",
    template: "",
  });

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  const onChangeFormField = (value: string | number, fieldName: string) => {
    setSystemForm((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await SettingService.createSetting({
          body: {
            ...systemForm,
          },
        });
      } else {
        await SettingService.updateSetting({
          body: {
            ...systemForm,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Event saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  //   const handleSettingSystem = () => {
  //     setIsLoading(true);
  //     const actionFnc = settingData
  //       ? SettingService.updateSetting
  //       : SettingService.createSetting;

  //     actionFnc({
  //       body: {
  //         type: SETTING_TYPES.SYSTEMS,
  //         value: [systemForm],
  //       },
  //     })
  //       .then(() => {
  //         //   handleGetSettingSystem();
  //         showToast(toast, "success", "Updated Successfully.");
  //       })
  //       .finally(() => setIsLoading(false));
  //   };

  // const handleSetFormValue = useCallback(
  //   (data: any) => data && setSystemForm(data?.value[0]),
  //   [auth]
  // );

  // const handleGetSettingSystem = () => {
  //   SettingService.getSettingsByType({
  //     type: SETTING_TYPES.SYSTEMS,
  //   }).then((resp) => {
  //     setSettingData(resp.setting);
  //     handleSetFormValue(resp.setting);
  //   });
  // };
  useEffect(() => {
    if (auth) {
    }
  }, [auth, data]);

  return (
    <Row className="card">
      <Row>
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={systemForm.name}
          onChange={(e) => onChangeFormField(e.target.value, "name")}
          placeholder="Name"
        />
      </Row>
      <Row>
        <label htmlFor="template">Template</label>
        <InputTextarea
          id="template"
          value={systemForm.template}
          onChange={(e) => onChangeFormField(e.target.value, "template")}
          placeholder="Template"
        />
      </Row>
    </Row>
  );
};

export default forwardRef(Details);
