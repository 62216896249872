import VDialog from "components/v-dialog";
import { Checkbox } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useRef, useState } from "react";
import { EditNameType } from "types/seats-map";
import { showToast } from "utils/common";

interface Props {
  showDialogEditName: boolean;
  editNameList: EditNameType[];
  setEditNameList: (value: EditNameType[]) => void;
  setShowDialogEditName: (value: boolean) => void;
  handleEditName: (data?: EditNameType[]) => void;
}

const EditNameDialog = ({
  showDialogEditName,
  editNameList,
  setEditNameList,
  setShowDialogEditName,
  handleEditName,
}: Props) => {
  const [form, setForm] = useState({
    from: 0,
    to: 0,
  });
  const [typeEdit, setTypeEdit] = useState({
    multiple: false,
    single: false,
  });
  const toastRef = useRef(null);

  const handleSubmit = () => {
    if (typeEdit.single) {
      return handleEditName();
    }
    if (!form.from && form.from !== 0)
      return showToast(toastRef, "error", "Please enter from number.");
    if (!form.to && form.to !== 0)
      return showToast(toastRef, "error", "Please enter to number.");
    if (form.from >= 0 && form.to >= 0 && form.to <= form.from)
      return showToast(
        toastRef,
        "error",
        "To number must be greater than from number."
      );

    let mainArrayNumber = [];
    const tempArrayNumber = Array.from(
      { length: form.to - form.from + 1 },
      (_, i) => i
    );

    tempArrayNumber.reduce((acc) => {
      const num = acc + 1;
      mainArrayNumber.push(num);
      return num;
    }, form.from - 1);

    let i = 0;
    let result: EditNameType[] = [];

    const getItem = (item: EditNameType) => {
      const newItem: EditNameType = {
        ...item,
        data: {
          ...item.data,
          name: `${item.data.name[0]}${mainArrayNumber[i] || ""}`,
        },
      };
      return result.push(newItem);
    };

    editNameList.forEach((item, index) => {
      if (index === 0) {
        return getItem(item);
      }

      if (item.rowIndex === editNameList[index - 1].rowIndex) {
        i++;
        return getItem(item);
      }
      i = 0;
      return getItem(item);
    });
    setEditNameList(result);
    handleEditName(result);
  };

  return (
    <VDialog
      header={"Edit Name"}
      isShown={showDialogEditName && !!editNameList?.length}
      onHide={() => setShowDialogEditName(false)}
      onSubmit={handleSubmit}
      style={{ width: "450px" }}
    >
      <Toast ref={toastRef} />
      <div
        className="flex flex-column w-full"
        style={{
          rowGap: 12,
        }}
      >
        <div
          className="flex flex-column w-full"
          style={{
            rowGap: 12,
          }}
        >
          <div
            className="flex align-items-center w-full"
            style={{
              columnGap: 12,
            }}
          >
            <Checkbox
              id="edit-multiple"
              checked={typeEdit.multiple}
              onChange={(e) =>
                setTypeEdit({
                  single: false,
                  multiple: e.checked,
                })
              }
            />
            <label htmlFor="edit-multiple">Edit Multiple Seats</label>
          </div>
          {typeEdit.multiple && (
            <div
              className="flex flex-column w-full"
              style={{
                rowGap: 8,
              }}
            >
              <div className="field col-12 px-0 m-0">
                <label htmlFor="from">From Number</label>
                <InputNumber
                  value={form.from}
                  onChange={(e) =>
                    setForm((prev) => ({
                      ...prev,
                      from: e.value,
                    }))
                  }
                />
              </div>
              <div className="field col-12 px-0 m-0">
                <label htmlFor="from">To Number</label>
                <InputNumber
                  value={form.to}
                  onChange={(e) =>
                    setForm((prev) => ({
                      ...prev,
                      to: e.value,
                    }))
                  }
                />
              </div>
            </div>
          )}
        </div>
        <div
          className="flex flex-column w-full"
          style={{
            rowGap: 12,
          }}
        >
          <div
            className="flex align-items-center w-full"
            style={{
              columnGap: 12,
            }}
          >
            <Checkbox
              id="edit-multiple"
              checked={typeEdit.single}
              onChange={(e) =>
                setTypeEdit({
                  multiple: false,
                  single: e.checked,
                })
              }
            />
            <label htmlFor="edit-multiple">Edit Each Seat</label>
          </div>
          {typeEdit.single && (
            <div
              className="flex flex-column w-full"
              style={{
                rowGap: 12,
              }}
            >
              {editNameList.map((item, index) => (
                <InputText
                  key={index}
                  id="colCustomize"
                  value={item?.data?.name || ""}
                  onChange={(e) => {
                    const cloneList = Array.from(editNameList);
                    cloneList[index] = {
                      ...item,
                      data: { ...item?.data, name: e.target.value },
                    };
                    setEditNameList(cloneList);
                  }}
                  required
                  autoFocus
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </VDialog>
  );
};

export default EditNameDialog;
