/* eslint-disable react-hooks/exhaustive-deps */
import VDialog from "components/v-dialog";
import { useLayoutEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { TextCustomize } from "types/seats-map";

interface Props {
  activeIndex: number;
  activeItem: TextCustomize;
  onHide: () => void;
  handleChangeTextCustomize: (data: TextCustomize) => void;
}

const TextCustomizeDialog = ({
  activeIndex,
  activeItem,
  onHide,
  handleChangeTextCustomize,
}: Props) => {
  const [form, setForm] = useState<TextCustomize>(null);

  const onChangeFormField = (value: string | number, fieldName: string) => {
    setForm((prev) => ({
      ...prev,
      style: {
        ...prev.style,
        [fieldName]: value,
      },
    }));
  };

  useLayoutEffect(() => {
    if (activeItem && activeIndex !== -1) {
      setForm(activeItem);
    }
  }, [activeItem, activeIndex]);

  return (
    <VDialog
      header={"Edit Name"}
      isShown={activeIndex !== -1}
      onHide={onHide}
      onSubmit={() => handleChangeTextCustomize(form)}
      style={{ width: "450px" }}
    >
      <div className="field col-12 mb-0">
        <label htmlFor="text">Text</label>
        <InputText
          id="text"
          value={form?.text}
          onChange={(e) =>
            setForm((prev) => ({
              ...prev,
              text: e.target.value,
            }))
          }
          required
          autoFocus
        />
      </div>
      <div className="field col-12 mb-0">
        <label htmlFor="color">Color</label>
        <InputText
          id="color"
          value={form?.style?.color}
          onChange={(e) => onChangeFormField(e.target.value, "color")}
          required
        />
      </div>
      <div className="field col-12 mb-0">
        <label htmlFor="fontSize">Font Size</label>
        <InputNumber
          id="fontSize"
          value={form?.style?.fontSize}
          onChange={(e) => onChangeFormField(e.value, "fontSize")}
          required
        />
      </div>
      <div className="field col-12 mb-0">
        <label htmlFor="fontWeight">Font Weight</label>
        <InputText
          id="fontWeight"
          value={form?.style?.fontWeight}
          onChange={(e) => onChangeFormField(e.target.value, "fontWeight")}
          required
        />
      </div>
      <div className="field col-12 mb-0">
        <label htmlFor="rotate">Rotation</label>
        <InputNumber
          id="rotate"
          value={form?.style?.rotate}
          onChange={(e) => onChangeFormField(e.value, "rotate")}
          required
        />
      </div>
    </VDialog>
  );
};

export default TextCustomizeDialog;
