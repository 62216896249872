import React, { useEffect, useState } from "react";
import StatisticServices from "services/statistics";
import { formatNumber } from "utils/common";

const Dashboard = (props: any) => {
  // const [lineOptions, setLineOptions] = useState(null);

  // const applyLightTheme = () => {
  //   const lineOptions: any = {
  //     plugins: {
  //       legend: {
  //         labels: {
  //           color: "#495057",
  //         },
  //       },
  //     },
  //     scales: {
  //       x: {
  //         ticks: {
  //           color: "#495057",
  //         },
  //         grid: {
  //           color: "#ebedef",
  //         },
  //       },
  //       y: {
  //         ticks: {
  //           color: "#495057",
  //         },
  //         grid: {
  //           color: "#ebedef",
  //         },
  //       },
  //     },
  //   };

  //   setLineOptions(lineOptions);
  // };

  // const applyDarkTheme = () => {
  //   const lineOptions: any = {
  //     plugins: {
  //       legend: {
  //         labels: {
  //           color: "#ebedef",
  //         },
  //       },
  //     },
  //     scales: {
  //       x: {
  //         ticks: {
  //           color: "#ebedef",
  //         },
  //         grid: {
  //           color: "rgba(160, 167, 181, .3)",
  //         },
  //       },
  //       y: {
  //         ticks: {
  //           color: "#ebedef",
  //         },
  //         grid: {
  //           color: "rgba(160, 167, 181, .3)",
  //         },
  //       },
  //     },
  //   };

  //   setLineOptions(lineOptions);
  // };

  // //   useEffect(() => {
  // //     const productService = new ProductService();
  // //     productService
  // //       .getProductsSmall()
  // //       .then((data: React.SetStateAction<null>) => setProducts(data));
  // //   }, []);

  // useEffect(() => {
  //   if (props.colorMode === "light") {
  //     applyLightTheme();
  //   } else {
  //     applyDarkTheme();
  //   }
  // }, [props.colorMode]);

  const [data, setData] = useState({
    tickets: 0,
    users: 0,
    orders: 0,
    revenues: 0,
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await StatisticServices.getAdminStatistic({
        query: {},
      });
      setData(res);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="grid">
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Orders</span>
              <div className="text-900 font-medium text-xl">{data.orders}</div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-blue-100 border-round"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i className="pi pi-shopping-cart text-blue-500 text-xl" />
            </div>
          </div>
          {/* <span className="text-green-500 font-medium">24 new </span>
          <span className="text-500">since last visit</span> */}
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Revenues</span>
              <div className="text-900 font-medium text-xl">
                {formatNumber(data.revenues, 0)} VND
              </div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-orange-100 border-round"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i className="pi pi-map-marker text-orange-500 text-xl" />
            </div>
          </div>
          {/* <span className="text-green-500 font-medium">%52+ </span>
          <span className="text-500">since last week</span> */}
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Customers</span>
              <div className="text-900 font-medium text-xl">{data.users}</div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-cyan-100 border-round"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i className="pi pi-inbox text-cyan-500 text-xl" />
            </div>
          </div>
          {/* <span className="text-green-500 font-medium">520 </span> */}
          {/* <span className="text-500">newly registered</span> */}
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Tickets</span>
              <div className="text-900 font-medium text-xl">{data.tickets}</div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-purple-100 border-round"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i className="pi pi-comment text-purple-500 text-xl" />
            </div>
          </div>
          {/* <span className="text-green-500 font-medium">85 </span> */}
          {/* <span className="text-500">responded</span> */}
        </div>
      </div>
    </div>
  );
};

// const comparisonFn: any = function (
//   prevProps: { location: { pathname: any }; colorMode: any },
//   nextProps: { location: { pathname: any }; colorMode: any }
// ) {
//   return (
//     prevProps.location.pathname === nextProps.location.pathname &&
//     prevProps.colorMode === nextProps.colorMode
//   );
// };

export default React.memo(Dashboard);
