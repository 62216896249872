import { forwardRef, MutableRefObject } from "react";
import Moveable from "./Moveable";
import { Checkbox } from "primereact/checkbox";
import { SeatMapRow, TextCustomize } from "types/seats-map";

interface Props {
  listTextCustomize: TextCustomize[];
  seats: SeatMapRow;
  stagePosition: string;
  rowsSelected: number[];
  seatsSelected: string[];
  handleSelectRows: (index: number) => void;
  setActiveTextCustomize: (index: number) => void;
  handleChangeTextCustomize: (item: TextCustomize) => void;
}

const SeatsMap = (
  {
    listTextCustomize,
    seats,
    stagePosition,
    rowsSelected,
    seatsSelected,
    handleSelectRows,
    setActiveTextCustomize,
    handleChangeTextCustomize,
  }: Props,
  scrollerRef: MutableRefObject<HTMLDivElement>
) => {
  return (
    <div
      ref={scrollerRef}
      id="select-container"
      className="select_container relative"
    >
      <div
        className="relative"
        style={{
          width: "max-content",
          margin: "auto",
          padding: 50,
          maxHeight: 550,
        }}
        ref={scrollerRef}
        id="selecto1"
      >
        {stagePosition === "front" && (
          <div
            className="flex align-items-center justify-content-center px-2 py-1 mx-auto mb-5"
            style={{
              background: "blue",
              width: "200px",
              borderRadius: "50px",
            }}
          >
            <p className="text-white text-center font-medium z-50">Sân Khấu</p>
          </div>
        )}
        <div
          className="flex flex-column align-items-center justify-content-center max-h-full h-full py-3 w-full relative"
          style={{
            rowGap: 8,
          }}
        >
          {listTextCustomize.length > 0 && (
            <div
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                zIndex: 50,
              }}
            >
              {listTextCustomize.map((item, index) => (
                <Moveable
                  key={index}
                  textCustomize={item}
                  index={index}
                  setActiveTextCustomize={setActiveTextCustomize}
                  handleChangeTextCustomize={handleChangeTextCustomize}
                />
              ))}
            </div>
          )}
          {seats?.map(
            (col, index) =>
              col.length > 0 && (
                <div
                  key={index}
                  className="flex flex-row align-items-center justify-content-center w-full relative"
                  style={{
                    columnGap: 8,
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: -36,
                      transform: "translateY(-50%)",
                    }}
                  >
                    <Checkbox
                      onChange={() => {
                        handleSelectRows(index);
                      }}
                      checked={rowsSelected.includes(index)}
                    />
                  </div>
                  {col?.map((seat, i) => (
                    <div className="flex flex-column w-fit relative" key={i}>
                      <div
                        key={i}
                        className={`flex justify-content-center align-items-center cursor-pointer seat ${
                          seatsSelected?.includes(seat.id) &&
                          !seat.isDisabled &&
                          "seat-selected"
                        }`}
                        style={{
                          background: seat?.isDisabled
                            ? "transparent"
                            : seat?.background,
                          color: seat?.isDisabled ? "transparent" : seat?.color,
                          width: 30,
                          height: 30,
                          minWidth: 30,
                          minHeight: 30,
                          maxWidth: 30,
                          maxHeight: 30,
                          borderRadius: 8,
                          fontSize: 12,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          padding: 4,
                          pointerEvents: seat?.isDisabled ? "none" : "auto",
                        }}
                        id={seat.id}
                      >
                        {seat?.name}
                      </div>
                    </div>
                  ))}
                </div>
              )
          )}
        </div>
        {stagePosition === "behind" && (
          <div
            className="flex align-items-center justify-content-center px-2 py-1 mx-auto mt-5"
            style={{
              background: "blue",
              width: "200px",
              borderRadius: "50px",
            }}
          >
            <p className="text-white text-center font-medium z-50">Sân Khấu</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default forwardRef(SeatsMap);
