import "react-app-polyfill/ie11";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { MasterProvider } from "contexts/MasterContext";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "index.css";

ReactDOM.render(
  <BrowserRouter>
    <DndProvider backend={HTML5Backend}>
      <MasterProvider>
        <App />
      </MasterProvider>
    </DndProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
