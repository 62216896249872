import qs from "query-string";
import { request } from "./request";

const revenue = async (req: any) => {
  try {
    const query = qs.stringify(req.query);
    const response = await request().get(`admin/statistics/revenue?${query}`);
    return response?.data?.data;
  } catch (error) {
    const errorMess = error?.response?.data?.message;
    throw errorMess;
  }
};

const getAdminStatistic = async (req?: any) => {
  try {
    const query = qs.stringify(req.query);
    const response = await request().get(
      `admin/statistics/adminStatistic?${query}`
    );
    return response?.data?.data;
  } catch (error) {
    const errorMess = error?.response?.data?.message;
    throw errorMess;
  }
};

const StatisticServices = {
  revenue,
  getAdminStatistic,
};

export default StatisticServices;
