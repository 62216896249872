/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useImperativeHandle } from "react";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { InputText } from "primereact/inputtext";
import TopicService from "services/topics";
import { COMMON_STATUS } from "utils/enum";
import { Dropdown } from "primereact/dropdown";
import { COMMON_STATUS_OPTIONS } from "utils/constant";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    name: "",
    key: "",
    status: COMMON_STATUS.ENABLE,
  };
  const [details, setDetails] = useState(emptyData);
  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) {
      setDetails(data);
    }
  }, [data]);

  const onChange = (value, name, field = null) => {
    let _details = { ...details };
    if (field) {
      _details[field][`${name}`] = value;
    } else {
      _details[`${name}`] = value;
    }
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await TopicService.updateById({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else {
        await TopicService.create(details);
      }
      setLoading(false);
      showToast(toast, "success", "Topic saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12">
        <label htmlFor="name">Categories Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onChange(e.target.value, "name")}
          placeholder="Categories Name"
          required
          autoFocus
        />
      </div>
      <div className="field col-6">
        <label htmlFor="key">Categories Key</label>
        <InputText
          id="key"
          value={details.key}
          onChange={(e) => onChange(e.target.value, "key")}
          placeholder="Categories-key"
          required
        />
      </div>
      <div className="field col-6">
        <label htmlFor="name">Status</label>
        <Dropdown
          defaultValue={details.status}
          value={details.status}
          options={COMMON_STATUS_OPTIONS}
          onChange={(e) => {
            onChange(e.value, "status");
          }}
          optionLabel="label"
          placeholder="Select Status"
          className="w-full"
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
