/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import MenuServices from "services/menus";
import {
  convertObjectToListValue,
  formatLocalTime,
  showToast,
} from "utils/common";
import Details from "./components/Details";
import VDialog from "components/v-dialog";
import VConfirm from "components/v-confirm";
import debounce from "lodash.debounce";
import { MultiSelect } from "primereact";
import { COMMON_STATUS, MENU_TYPE } from "utils/enum";
// import Sort from "./components/Sort";
import { TreeTable } from "primereact/treetable";

const View = () => {
  const [data, setData] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [treeMenus, setTreeMenus] = useState([]);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    status: convertObjectToListValue(COMMON_STATUS),
    type: convertObjectToListValue(MENU_TYPE),
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    limit: 9999,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const refDialogSort = useRef(null);

  const refDetail = useRef(null);
  const refSort = useRef(null);

  useEffect(() => {
    loadData();
    loadTreeMenus();
  }, [lazyParams, globalFilter]);

  const loadData = async () => {
    try {
      const res: any = await MenuServices.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setAllData(res.docs);
      } else setData([]);
    } catch (error) {
      setData([]);
    }
  };

  const loadTreeMenus = () => {
    MenuServices.getTreeMenu({}).then((res: any) => {
      if (res) {
        setTreeMenus(res);
      }
    });
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  const openSort = () => {
    refDialogSort.current.show();
  };

  //CREATE OR NEW
  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };
  const editCategory = async (data) => {
    if (data) {
      setDetail(data);
      refDialogDetail.current.show();
    }
  };

  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };
  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };
  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  const onSubmitDialogSort = () => {
    refSort.current.submit();
  };

  const onCancelDialogSort = () => {
    refDialogSort.current.close();
  };

  const setLoadingSaveSort = (flg) => {
    refDialogSort.current.setLoading(flg);
  };

  // DELETE
  const confirmDelete = (project) => {
    setDetail(project);
    refDialogDelete.current.show();
  };

  const handleDelete = async () => {
    try {
      await MenuServices.deleteMenu({
        params: {
          id: detail._id,
        },
      });
      const _data = data.filter((val) => val._id !== detail._id);
      setData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "Category Deleted");
      loadData();
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const getItems = (mapList: any, parent_id: string) => {
    let items = mapList.filter((e: any) => e.parent_id === parent_id);
    items.forEach((el: any) => {
      const items = getItems(mapList, el._id);
      if (items && items != null && items.length > 0) {
        el.children = items;
        el.leaf = false;
      }
    });
    return items;
  };

  useEffect(() => {
    if (data.length > 0) {
      const mapAll = allData.map((e: any) => ({
        ...e,
        key: e._id != null ? String(e._id) : null,
        keyVal: e.key,
        parent_id: e.parent_id ? e.parent_id : null,
      }));

      const mapList = data.map((e: any) => ({
        ...e,
        key: e._id != null ? String(e._id) : null,
        keyVal: e.key,
        parent_id: e.parent_id ? e.parent_id : null,
      }));
      const tree = mapList.filter((e: any) => e.parent_id == null);
      tree.forEach((el: any) => {
        const items = getItems(mapAll, el._id);
        if (items && items != null && items.length > 0) {
          el.children = items;
          el.leaf = false;
        }
      });

      if (tree.length === 0 && mapList) {
        const treeChild = mapList.map((el: any) => {
          const items = getItems(mapAll, el._id);
          if (items && items != null && items.length > 0) {
            el.children = items;
            el.leaf = false;
          }
          return el;
        });
        setTreeData([...treeChild]);
      } else {
        setTreeData([...tree]);
      }
    } else setTreeData([]);
  }, [data, allData]);

  //RENDER
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
          {/* <Button
            label="Sort"
            icon="bx bx-sort-alt-2"
            className="p-button-warn mr-2"
            onClick={openSort}
          /> */}
        </div>
      </React.Fragment>
    );
  };
  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 hidden"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help hidden"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editCategory(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:justify-content-between">
      <h5 className="m-0">Manage Menus</h5>
      <div className="grid my-2">
        <span className="block m-1 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e: any) => onSearch("search", e.target.value)}
            placeholder="Search..."
          />
        </span>{" "}
        <MultiSelect
          value={globalFilter.status}
          options={Object.keys(COMMON_STATUS).map((k) => ({
            label: k,
            value: COMMON_STATUS[k],
          }))}
          onChange={(e: any) => onSearch("status", e.value)}
          optionLabel="label"
          placeholder="Status"
          maxSelectedLabels={3}
          className={"w-15rem m-1"}
          showClear
        />
        <MultiSelect
          value={globalFilter.type}
          options={Object.keys(MENU_TYPE).map((k) => ({
            label: k,
            value: MENU_TYPE[k],
          }))}
          onChange={(e: any) => onSearch("type", e.value)}
          optionLabel="label"
          placeholder="type"
          className={"w-15rem m-1"}
          showClear
          maxSelectedLabels={3}
        />
      </div>
    </div>
  );

  const threedot = (sequence: string, limit: number) => {
    if (sequence?.length >= limit) {
      return sequence.slice(0, limit) + "...";
    }
    return sequence;
  };

  return (
    <div className="grid View-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <TreeTable
            ref={dt}
            value={treeData}
            header={header}
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            resizableColumns
            columnResizeMode="expand"
            scrollable
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onPage={onPage}
            onSort={onSort}
          >
            <Column
              expander
              style={{ flexGrow: 1, width: "30%", whiteSpace: "nowrap" }}
              field="name"
              header="Name"
              body={(rowData) => threedot(rowData.name, 30)}
            ></Column>
            <Column
              field="type"
              header="Type"
              body={(rowData) => <span>{rowData.type}</span>}
              style={{ flexGrow: 1, width: "10%", textTransform: "uppercase" }}
            ></Column>
            <Column
              style={{ flexGrow: 1, width: "8%" }}
              field="order"
              header="Order"
              body={(rowData) => rowData.order}
            ></Column>
            <Column
              style={{ flexGrow: 1, width: "15%" }}
              field="status"
              header="Status"
              body={(rowData) => (
                <span
                  className={`v-badge status-${rowData.status.toLowerCase()}`}
                >
                  {rowData.status}
                </span>
              )}
            ></Column>

            <Column
              style={{ flexGrow: 1, width: "15%" }}
              field="createdAt"
              header="Created At"
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
            <Column
              body={actionBodyTemplate}
              style={{ flexGrow: 1, width: "15%" }}
            ></Column>
          </TreeTable>

          <VDialog
            ref={refDialogDetail}
            header="Menu Details"
            onSubmit={onSumitDialogDetail}
          >
            <Details
              ref={refDetail}
              data={detail}
              toast={toast}
              reload={loadData}
              treeMenus={treeMenus}
              loadTreeMenus={loadTreeMenus}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>
          {/* <VDialog
            ref={refDialogSort}
            header="Categories Sort"
            onSubmit={onSubmitDialogSort}
          >
            <Sort
              ref={refSort}
              data={data}
              onCancel={onCancelDialogSort}
              setLoading={setLoadingSaveSort}
              toast={toast}
              reload={loadData}
            />
          </VDialog> */}
          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
