/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useImperativeHandle } from "react";
import { InputText } from "primereact/inputtext";
import UserService from "services/users";
import { formatDateValue, showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { GENDER_OPTIONS, ROLE, USER_STATUS } from "utils/constant";
import { Calendar } from "primereact/calendar";
import VLocation from "components/v-location";

const Details = (props, ref) => {
  const { data, roleOptions, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    avatar: "",
    full_name: "",
    company_name: "",
    role: "",
    email: "",
    phone: "",
    status: "confirmed",
    password: null,
    gender: "male",
    birthday: null,
    agency_level: null,
    address: {
      country: null,
      province: null,
      district: null,
      ward: null,
    },
  };
  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) {
      setDetails({
        ...data,
        password: null,
      });
    }
  }, [data]);

  const onChange = (value, name, field = null) => {
    let _details = { ...details };
    if (field) {
      _details[field][`${name}`] = value;
    } else {
      _details[`${name}`] = value;
    }
    setDetails(_details);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || e?.value || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const onDropdownChange = (e, name) => {
    const val = e.value || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await UserService.updateUser({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else {
        await UserService.createUser({
          body: {
            ...details,
            ...(details.role === ROLE.AGENCY ? { agency_level: 1 } : {}),
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "User saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="col-12 ">
        {details.avatar && (
          <img
            src={details.avatar}
            alt={details.avatar}
            width="150"
            className="mt-0 mx-auto mb-5 block shadow-2"
          />
        )}
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="description">Role</label>
        <Dropdown
          defaultValue={details.role}
          value={details.role}
          options={roleOptions}
          onChange={(e) => onDropdownChange(e, "role")}
          optionLabel="label"
          placeholder="Select role"
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="name">Email</label>
        <InputText
          id="email"
          value={details.email}
          onChange={(e) => onInputChange(e, "email")}
          required
        />
      </div>

      <div className="field col-12 md:col-4">
        <label htmlFor="name">Full Name</label>
        <InputText
          id="fullName"
          value={details.full_name}
          onChange={(e) => onInputChange(e, "full_name")}
          required
          autoFocus
        />
      </div>
      {details.role === ROLE.AGENCY && (
        <div className="field col-12 md:col-4">
          <label htmlFor="name">Company Name</label>
          <InputText
            id="fullName"
            value={details.company_name}
            onChange={(e) => onInputChange(e, "company_name")}
            required
          />
        </div>
      )}
      <div className="field col-12 md:col-4">
        <label htmlFor="description">Phone</label>
        <InputText
          type="number"
          id="phone"
          value={details.phone}
          onChange={(e) => onInputChange(e, "phone")}
          required
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="description">Gender</label>
        <Dropdown
          defaultValue={details.gender}
          value={details.gender}
          options={GENDER_OPTIONS}
          onChange={(e) => onDropdownChange(e, "gender")}
          optionLabel="label"
          placeholder="Select gender"
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="description">Birthday</label>
        <Calendar
          className="w-full"
          dateFormat="dd/mm/yy"
          placeholder="Birthday"
          value={
            details.birthday ? formatDateValue(details.birthday, "minus") : ""
          }
          onChange={(e: any) => {
            const { value } = e;
            const formattedValue = formatDateValue(value, "plus");
            onChange(formattedValue, "birthday");
          }}
          showButtonBar
          maxDate={new Date()}
          showIcon
        />
      </div>
      <VLocation
        address={details.address}
        setAddress={(address) => onChange(address, "address")}
      />
      <div className="field col-12 md:col-6">
        <label htmlFor="description">Status</label>
        <Dropdown
          defaultValue={details.status}
          value={details.status}
          options={USER_STATUS}
          onChange={(e) => onDropdownChange(e, "status")}
          optionLabel="label"
          placeholder="Select status"
        />
      </div>

      <div className="field col-12 md:col-6 ">
        <label htmlFor="name">Password</label>
        <InputText
          id="password"
          onChange={(e) => onInputChange(e, "password")}
          required
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
