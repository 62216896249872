/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { formatLocalTime, formatNumber } from "utils/common";
import { Calendar } from "primereact/calendar";
import debounce from "lodash.debounce";
import { Panel } from "primereact";
import { OrderDataDoc } from "types/order";
import { PAYMENT_STATUS_OPTIONS, ROLE } from "utils/constant";
import { Dropdown } from "primereact/dropdown";
import EventServices from "services/events";
import { EventDataDoc } from "types/event";
import UserService from "services/users";
import { UserData } from "types/users";
import StatisticServices from "services/statistics";

const Revenue = () => {
  const [data, setData] = useState<OrderDataDoc[]>([]);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    payment_status: "success",
    start: null,
    end: null,
    event_id: null,
    agency: null,
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [events, setEvents] = useState<EventDataDoc[]>([]);
  const [agencies, setAgencies] = useState<UserData[]>([]);
  const [sumData, setSumData] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [loadingExport, setLoadingExport] = useState(false);

  const toast = useRef(null);
  const dt = useRef(null);

  const loadData = async () => {
    try {
      const res = await StatisticServices.revenue({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
        },
      });
      if (res && res.res) {
        setData(res.res.docs);
        setSumData(res.sum);
        setDiscount(res.discount);
        setTotalRecords(res.res.totalDocs);
      } else {
        setData([]);
        setSumData(null);
        setTotalRecords(0);
      }
    } catch (error) {
      setData([]);
    }
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  useEffect(() => {
    EventServices.getAll().then((resp) => setEvents(resp));
  }, []);

  useEffect(() => {
    UserService.getUsers({
      query: {
        search: "",
        role: ROLE.AGENCY,
      },
    }).then((resp) => setAgencies(resp.users));
  }, []);

  const exportExcel = async () => {
    try {
      setLoadingExport(true);
      const res: any = await StatisticServices.revenue({
        query: {
          ...lazyParams,
          ...globalFilter,
          rows: 10000,
          page: 1,
        },
      });
      import("xlsx").then((xlsx) => {
        const _data = [
          ...(res?.res?.docs as OrderDataDoc[]).map((item, index) => ({
            STT: index + 1,
            "Order ID": item.order_code,
            "Event Name": item.event[0]?.name,
            Email: item.email,
            "Phone Number": item.phone_number,
            "Booked Seats": renderBookedSeats(item),
            Amount: `${formatNumber(item.amount, 0)} VND`,
            "Payment Status": PAYMENT_STATUS_OPTIONS.find(
              (s) => s.value === item?.payment_status
            )?.label,
            "Payment At": new Date(
              item.payment_at ? item.payment_at : item.createdAt
            ).toLocaleString("vi"),
            "Created At": new Date(item.createdAt).toLocaleString("vi"),
            "Total Amount": "",
            "Total Tickets": "",
          })),
        ];
        const worksheet = xlsx.utils.json_to_sheet(_data);
        worksheet["!cols"] = [
          { wpx: 50 },
          { wpx: 100 },
          { wpx: 300 },
          { wpx: 200 },
          { wpx: 100 },
          { wpx: 150 },
          { wpx: 100 },
          { wpx: 100 },
          { wpx: 150 },
          { wpx: 150 },
          { wpx: 150 },
          { wpx: 150 },
        ]; // Adjust column widths if needed
        worksheet[`K2`] = {
          v: `${formatNumber(sumData?.amount, 0)} VND`,
          t: "s",
          s: {
            font: {
              bold: true,
              size: 16,
            },
          },
        };
        worksheet[`L2`] = {
          v: `${formatNumber(sumData?.ticket_count, 0)} Tickets`,
        };
        const workbook = {
          Sheets: { "STalent Revenue": worksheet },
          SheetNames: ["STalent Revenue"],
        };
        const excelBuffer = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        saveAsExcelFile(excelBuffer, "Statistics Revenue");
        setLoadingExport(false);
      });
    } catch (error) {
      setLoadingExport(false);
    }
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + new Date().toLocaleString("vi") + EXCEL_EXTENSION
        );
      }
    });
  };

  const renderBookedSeats = (rowData: OrderDataDoc) => {
    const { booking_seats, seats_map } = rowData;
    if (seats_map) {
      const flatSeats = seats_map?.seats?.flat().flat();
      const seats = flatSeats
        .filter((item) => booking_seats.includes(item.id))
        ?.map((item) => item.name);
      return seats.join(", ");
    }
  };

  //RENDER
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            disabled={true}
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  };
  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          loading={loadingExport}
          className="p-button-help"
          onClick={exportExcel}
        />
      </React.Fragment>
    );
  };
  // const actionBodyTemplate = (rowData) => {
  //   return (
  //     <div className="actions">
  //       <Button
  //         icon="pi pi-pencil"
  //         className="p-button-rounded p-button-sm p-button-success mr-2"
  //         disabled={true}
  //         onClick={() => editProduct(rowData)}
  //       />
  //       <Button
  //         icon="pi pi-trash"
  //         disabled={true}
  //         className="p-button-rounded p-button-sm p-button-warning mr-2"
  //         onClick={() => confirmDelete(rowData)}
  //       />
  //     </div>
  //   );
  // };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <div className="grid my-2 align-items-center w-full">
          <span className="block p-input-icon-left col-12 md:col-3">
            <i className="ml-1 pi pi-search" />
            <InputText
              className="w-full"
              type="search"
              onInput={(e: any) => onSearch("search", e.target.value)}
              placeholder="Search..."
            />
          </span>{" "}
          <div className="col-12 md:col-3">
            <Calendar
              className="w-full"
              id="time24"
              placeholder="Start Date"
              value={globalFilter.start}
              onChange={(e: any) => onSearch("start", e.value)}
              maxDate={new Date()}
              showButtonBar
            />{" "}
          </div>
          <div className="col-12 md:col-3">
            <Calendar
              className="w-full"
              id="time24"
              placeholder="End Date"
              value={globalFilter.end}
              onChange={(e: any) => onSearch("end", e.value)}
              maxDate={new Date()}
              showButtonBar
            />
          </div>
          <div className="col-12 md:col-3">
            <Dropdown
              value={globalFilter.payment_status}
              options={PAYMENT_STATUS_OPTIONS}
              onChange={(e: any) => onSearch("payment_status", e.value)}
              optionLabel="label"
              placeholder="Payment Status"
              showClear
              className={"w-full"}
            />
          </div>
          <div className="col-12 md:col-3">
            <Dropdown
              value={globalFilter.event_id}
              options={events?.map((item) => ({
                label: item.name,
                value: item._id,
              }))}
              onChange={(e: any) => onSearch("event_id", e.value)}
              optionLabel="label"
              placeholder="Select Event"
              showClear
              className={"w-full"}
            />
          </div>
          <div className="col-12 md:col-3">
            <Dropdown
              value={globalFilter.agency}
              options={agencies?.map((item) => ({
                label: item.email,
                value: item._id,
              }))}
              onChange={(e: any) => onSearch("agency", e.value)}
              optionLabel="label"
              placeholder="Select Agency"
              showClear
              className={"w-full"}
            />
          </div>
        </div>
      </div>
    </Panel>
  );

  const footer = (
    <>
      {sumData && (
        <div
          className="flex flex-column"
          style={{
            width: "fit-content",
          }}
        >
          <div className="flex align-items-center" style={{}}>
            <strong>Amount: </strong>
            <strong>{`${formatNumber(sumData?.amount, 0)} VND`}</strong>
          </div>
          <div
            className="flex align-items-center"
            style={{
              columnGap: 8,
            }}
          >
            <strong>Tickets Count: </strong>
            <strong>{`${formatNumber(
              sumData?.ticket_count,
              0
            )} Tickets`}</strong>
          </div>
        </div>
      )}
      {discount && (
        <div
          className="flex flex-column"
          style={{
            width: "fit-content",
          }}
        >
          <div
            className="flex align-items-center"
            style={{
              columnGap: 8,
            }}
          >
            <strong>Level current: </strong>
            <strong>{discount.name}</strong>
          </div>
          <div
            className="flex align-items-center"
            style={{
              columnGap: 8,
            }}
          >
            <strong>Comission percent: </strong>
            <strong>{`${discount.percent} %`}</strong>
          </div>
        </div>
      )}
    </>
  );

  return (
    <div className="grid View-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={data}
            header={header}
            footer={footer}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            //panigate
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            {/* <Column
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "100px" }}
            ></Column> */}
            <Column
              field="order_code"
              header="Order Code"
              style={{ flexGrow: 1, flexBasis: "120px" }}
            ></Column>
            <Column
              header="Event Name"
              style={{
                flexGrow: 1,
                flexBasis: "200px",
                textOverflow: "ellipsis",
                overflowX: "auto",
                scrollbarWidth: "none",
                whiteSpace: "nowrap",
              }}
              body={(rowData) => rowData?.event?.name}
            ></Column>
            <Column
              field="email"
              header="Email"
              style={{
                flexGrow: 1,
                flexBasis: "200px",
                textOverflow: "ellipsis",
                overflowX: "auto",
                scrollbarWidth: "none",
                whiteSpace: "nowrap",
              }}
            ></Column>
            <Column
              field="phone_number"
              header="Phone Number"
              style={{ flexGrow: 1, flexBasis: "120px" }}
            ></Column>
            <Column
              field="booking_seats"
              header="Booked Seats"
              style={{ flexGrow: 1, flexBasis: "120px" }}
              body={(rowData) => renderBookedSeats(rowData)}
            ></Column>
            <Column
              field="amount"
              header="Amount"
              style={{ flexGrow: 1, flexBasis: "120px" }}
              body={(rowData) => `${formatNumber(rowData.amount, 0)} VND`}
            ></Column>
            <Column
              field="payment_status"
              header="Payment Status"
              style={{ flexGrow: 1, flexBasis: "120px" }}
              body={(rowData) =>
                PAYMENT_STATUS_OPTIONS.find(
                  (item) => item.value === rowData?.payment_status
                )?.label
              }
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "100px" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Revenue, comparisonFn);
