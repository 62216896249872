import { v4 as uuidv4 } from "uuid";

export const getUrlImage = (img) => {
  return `${
    process.env.REACT_APP_IMG || "http://localhost:3001/uploads"
  }/${img}`;
};

export function copyText(text: string) {
  return navigator.clipboard.writeText(text);
}

export const formatNumber = (number, minimumFractionDigits = 2) => {
  return new Intl.NumberFormat([], { minimumFractionDigits }).format(number);
};

export const formatCurrencyByType = (
  number,
  type = "en-US",
  currency = "USD",
  minimumFractionDigits = 2
) => {
  return new Intl.NumberFormat(type || [], {
    minimumFractionDigits,
    style: "currency",
    currency,
  }).format(number);
};

export const formatLocalTime = (data, locale = "vi") => {
  return new Date(data).toLocaleString(locale);
};

export const showToast = (toast, type = "success", messages = "") => {
  let summary = "Successful";

  switch (type) {
    case "success":
      summary = "Success Message";
      break;
    case "info":
      summary = "Info Message";
      break;
    case "warn":
      summary = "Warn Message";
      break;
    case "error":
      summary = "Error Message";
      break;
    default:
      break;
  }

  toast?.current?.show({
    severity: type,
    summary: summary,
    detail: messages,
    life: 2000,
  });
};

export const checkIncludeString = (contain, review) => {
  return contain.toLowerCase().includes(review.toLowerCase());
};

export const compareDate = (date1, date2) => {
  return new Date(date1).getTime() - new Date(date2).getTime();
};

export const getUrlHash = (hash) => {
  return `${
    process.env.REACT_APP_VZX_SCAN || "https://block.vzonex.com"
  }/tx/${hash}`;
};

export const getFromTo = (data) => {
  return `${(data || "").slice(0, 5)}...${data.slice(
    data.length - 3,
    data.length
  )}`;
};

export const convertObjectToListValue = (object: {}) => {
  return Object.keys(object).map((k) => object[k]);
};

export const selectEmpty = { id: "-1", label: "Select", key: "-1" };

export const formatDateValue = (date: any, type: "plus" | "minus") => {
  const formattedValue = new Date(date);
  formattedValue.setDate(
    type === "plus"
      ? formattedValue.getDate() + 1
      : formattedValue.getDate() - 1
  );
  return formattedValue;
};

export const ALPHABET = `ABCDEFGHIJKLMNOPQRSTUVWXYZ`;

export const DEFAULT_PRICE = [
  {
    id: uuidv4(),
    label: "300.000 VND",
    value: 300000,
  },
  {
    id: uuidv4(),
    label: "400.000 VND",
    value: 400000,
  },
  {
    id: uuidv4(),
    label: "600.000 VND",
    value: 600000,
  },
  {
    id: uuidv4(),
    label: "800.000 VND",
    value: 800000,
  },
  {
    id: uuidv4(),
    label: "1.200.000 VND",
    value: 1200000,
  },
];
