import { VoucherData, VoucherDataDoc } from "types/voucher";
import { request } from "./request";
import qs from "query-string";

const search = async (req: any): Promise<VoucherData> => {
  try {
    const query = qs.stringify(req.query);
    const response = await request().get(`admin/vouchers/search?${query}`);
    return response?.data?.data;
  } catch (error) {
    const errorMess = error?.response?.data?.message;
    throw errorMess;
  }
};

const createVoucher = async (data: { body: any }): Promise<VoucherDataDoc> => {
  try {
    const response = await request().post(`admin/vouchers`, data.body);
    return response?.data?.data;
  } catch (error) {
    const errorMess = error?.response?.data?.message;
    throw errorMess;
  }
};

const updateVoucher = async (req: {
  params: { id: string };
  body: any;
}): Promise<VoucherDataDoc> => {
  try {
    const response = await request().put(
      "admin/vouchers/" + req.params.id,
      req.body
    );
    return response?.data?.data;
  } catch (error) {
    const errorMess = error?.response?.data?.message;
    throw errorMess;
  }
};

const getAll = async (): Promise<VoucherDataDoc[]> => {
  try {
    const response = await request().get(`admin/vouchers`);
    return response?.data?.data;
  } catch (error) {
    const errorMess = error?.response?.data?.message;
    throw errorMess;
  }
};

const deleteVoucher = async (req: { params: { id: string } }) => {
  try {
    const response = await request().delete(`admin/vouchers/${req.params.id}`);
    return response?.data?.data;
  } catch (error) {
    const errorMess = error?.response?.data?.message;
    throw errorMess;
  }
};

const VoucherServices = {
  search,
  getAll,
  createVoucher,
  updateVoucher,
  deleteVoucher,
};

export default VoucherServices;
