import { ColorPicker } from "primereact/colorpicker";
import { InputText } from "primereact/inputtext";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import ThemesService from "services/themes";
import { showToast } from "utils/common";

const emtyData = {
  id: "",
  name: "",
  backgroundColor: "",
  formBgColor: "",
  buttonBackground: "",
  buttonColor: "",
  textColor: "",
};

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const [form, setForm] = useState(emtyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      handleSubmit();
    },
  }));

  const onInputChange = (value, name) => {
    setForm((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (!form.id) {
        ThemesService.create(form).then((resp: any) => setForm(resp));
        showToast(toast, "success", "Created Successfully.");
      } else {
        ThemesService.updateById({
          params: {
            id: form?.id,
          },
          body: form,
        }).then((resp: any) => setForm(resp));
        showToast(toast, "success", "Updated Successfully.");
      }
      setLoading(false);
      await reload();
      onCancel();
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  useEffect(() => {
    if (data) {
      setForm(data);
    }
  }, [data]);

  return (
    <div className="grid">
      <div className="field col-12 md:col-6 mb-0">
        <div className="field col-12 mb-0">
          <label>Theme Name</label>
          <InputText
            value={form?.name}
            onChange={(e) => onInputChange(e.target.value, "name")}
            required
            autoFocus
            className="w-full mr-3"
          />
        </div>
      </div>
      <div className="field col-12 md:col-6 mb-0">
        <div className="field col-12 mb-0">
          <label>Background</label>
          <div className="flex w-full align-items-center">
            <InputText
              id="color"
              value={form?.backgroundColor}
              onChange={(e) => onInputChange(e.target.value, "backgroundColor")}
              required
              autoFocus
              className="w-full mr-3"
            />
            <ColorPicker
              style={{
                width: 30,
                height: 30,
                borderRadius: 8,
              }}
              value={form?.backgroundColor}
              onChange={(e) =>
                onInputChange(`#${e.target.value as string}`, "backgroundColor")
              }
            />
          </div>
        </div>
      </div>
      <div className="field col-12 md:col-6 mb-0">
        <div className="field col-12 mb-0">
          <label>Form Background</label>
          <div className="flex w-full align-items-center">
            <InputText
              id="color"
              value={form?.formBgColor}
              onChange={(e) => onInputChange(e.target.value, "formBgColor")}
              required
              className="w-full mr-3"
            />
            <ColorPicker
              style={{
                width: 30,
                height: 30,
                borderRadius: 8,
              }}
              value={form?.formBgColor}
              onChange={(e) =>
                onInputChange(`#${e.target.value as string}`, "formBgColor")
              }
            />
          </div>
        </div>
      </div>
      <div className="field col-12 md:col-6 mb-0">
        <div className="field col-12 mb-0">
          <label>Button Background</label>
          <div className="flex w-full align-items-center">
            <InputText
              id="color"
              value={form?.buttonBackground}
              onChange={(e) =>
                onInputChange(e.target.value, "buttonBackground")
              }
              required
              autoFocus
              className="w-full mr-3"
            />
            <ColorPicker
              style={{
                width: 30,
                height: 30,
                borderRadius: 8,
              }}
              value={form?.buttonBackground}
              onChange={(e) =>
                onInputChange(
                  `#${e.target.value as string}`,
                  "buttonBackground"
                )
              }
            />
          </div>
        </div>
      </div>
      <div className="field col-12 md:col-6 mb-0">
        <div className="field col-12 mb-0">
          <label>Button Color</label>
          <div className="flex w-full align-items-center">
            <InputText
              id="color"
              value={form?.buttonColor}
              onChange={(e) => onInputChange(e.target.value, "buttonColor")}
              required
              className="w-full mr-3"
            />
            <ColorPicker
              style={{
                width: 30,
                height: 30,
                borderRadius: 8,
              }}
              value={form?.buttonColor}
              onChange={(e) =>
                onInputChange(`#${e.target.value as string}`, "buttonColor")
              }
            />
          </div>
        </div>
      </div>
      <div className="field col-12 md:col-6 mb-0">
        <div className="field col-12 mb-0">
          <label>Text Color</label>
          <div className="flex w-full align-items-center">
            <InputText
              id="color"
              value={form?.textColor}
              onChange={(e) => onInputChange(e.target.value, "textColor")}
              required
              className="w-full mr-3"
            />
            <ColorPicker
              style={{
                width: 30,
                height: 30,
                borderRadius: 8,
              }}
              value={form?.textColor}
              onChange={(e) =>
                onInputChange(`#${e.target.value as string}`, "textColor")
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Details);
