import { request, parseErrorResponse } from "./request";

const uploadFile = ({ body }) =>
  new Promise((rs, rj) => {
    request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .post("/uploads", body)
      .then((res) => {
        const { data } = res.data;
        if (data) rs(data);
        else {
          rj(new Error("Something went error"));
        }
      })
      .catch(parseErrorResponse)
      .then(rj);
  });

const UploadService = {
  uploadFile,
};

export default UploadService;
