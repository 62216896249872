import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { formatNumber } from "utils/common";
import { EventDataDoc, SeatOption } from "types/event";
import { EditNameType } from "types/seats-map";

interface Props {
  colCustomize: number;
  editNameList: EditNameType[];
  seatsSelected: string[];
  rowsSelected: number[];
  isDisabledUndo: boolean;
  isDisabledRedo: boolean;
  refConfirmDisable: any;
  refConfirmDeleteRow: any;
  seatOption: SeatOption;
  eventSelected: EventDataDoc | null;
  isTemplate: boolean;

  setColCustomize: (value: number) => void;
  handleCustomize: () => void;
  setShowDialogEditName: (value: boolean) => void;
  undo: (steps?: number) => void;
  redo: (steps?: number) => void;
  setSeatOption: (data: SeatOption) => void;
  handleChangeSeat: (data: SeatOption) => void;
  handleAddTextCustomize: () => void;
}

const Actions = ({
  colCustomize,
  editNameList,
  rowsSelected,
  seatsSelected,
  isDisabledUndo,
  isDisabledRedo,
  refConfirmDisable,
  refConfirmDeleteRow,
  seatOption,
  eventSelected,
  isTemplate,

  setColCustomize,
  handleCustomize,
  setShowDialogEditName,
  undo,
  redo,
  setSeatOption,
  handleChangeSeat,
  handleAddTextCustomize,
}: Props) => {
  return (
    <div className="field col-12">
      {!isTemplate && (
        <div className="field col-12 pl-0">
          <label htmlFor="description">Seat Options</label>
          <Dropdown
            defaultValue={seatOption?.id}
            value={seatOption?.id}
            options={eventSelected?.seatOptions?.map((item) => ({
              label: `${item?.name} - ${formatNumber(item.price, 0)} VND`,
              value: item?.id,
            }))}
            disabled={!seatsSelected?.length}
            onChange={(e) => {
              const data = eventSelected?.seatOptions?.find(
                (item) => item?.id === e.value
              );
              setSeatOption(data);
              handleChangeSeat(data);
            }}
            optionLabel="label"
            placeholder="Select Seat"
          />
        </div>
      )}
      <div className="grid align-items-end w-full">
        <div className="field col-8">
          <label htmlFor="colCustomize">Customize Column</label>
          <InputText
            id="colCustomize"
            value={colCustomize}
            onChange={(e) => setColCustomize(+e.target.value)}
            required
            autoFocus
          />
        </div>
        <div className="field col-4">
          <Button
            onClick={handleCustomize}
            disabled={!rowsSelected.length}
            className="flex align-items-center justify-content-center"
            style={{
              width: "fit-content",
            }}
          >
            Save
          </Button>
        </div>
        <div
          className="flex field align-items-center col-12 mb-0"
          style={{
            columnGap: 12,
            flexWrap: "wrap",
            maxWidth: "100%",
          }}
        >
          {!isTemplate && (
            <Button
              onClick={handleAddTextCustomize}
              className="flex align-items-center justify-content-center flex-1"
              style={{
                width: "fit-content",
                whiteSpace: "nowrap",
              }}
            >
              Additional Text
            </Button>
          )}
          <Button
            onClick={() => setShowDialogEditName(true)}
            disabled={!editNameList.length}
            className="flex align-items-center justify-content-center flex-1"
            style={{
              width: "fit-content",
              whiteSpace: "nowrap",
            }}
          >
            Edit Name
          </Button>
        </div>
        <div
          className="flex field align-items-center col-12 mb-0"
          style={{
            columnGap: 12,
            flexWrap: "wrap",
            maxWidth: "100%",
          }}
        >
          <Button
            onClick={() => refConfirmDisable.current.show()}
            disabled={!seatsSelected.length}
            className="flex align-items-center justify-content-center flex-1"
            style={{
              width: "fit-content",
            }}
          >
            Disabled
          </Button>
          <Button
            onClick={() => refConfirmDeleteRow.current.show()}
            disabled={!rowsSelected.length && !seatsSelected.length}
            className="flex align-items-center justify-content-center flex-1"
            style={{
              width: "fit-content",
              whiteSpace: "nowrap",
            }}
          >
            Delete
          </Button>
        </div>
        <div
          className="flex field align-items-center col-12"
          style={{
            columnGap: 12,
          }}
        >
          <Button
            onClick={() => undo()}
            disabled={isDisabledUndo}
            className="flex align-items-center justify-content-center"
            style={{
              width: "fit-content",
              whiteSpace: "nowrap",
            }}
          >
            Undo
          </Button>
          <Button
            onClick={() => redo()}
            disabled={isDisabledRedo}
            className="flex align-items-center justify-content-center"
            style={{
              width: "fit-content",
              whiteSpace: "nowrap",
            }}
          >
            Redo
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Actions;
