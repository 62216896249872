import { create } from "zustand";
import { temporal } from "zundo";
import { shallow } from "zustand/shallow";
import { SeatMapRow } from "types/seats-map";

interface StoreState {
  seats: SeatMapRow;
  setSeats: (seats: SeatMapRow) => void;
}

const useStoreWithUndo = create<StoreState>()(
  temporal(
    (set) => ({
      seats: [],
      setSeats: (seats) => set(() => ({ seats })),
    }),
    {
      limit: 5,
      equality: (pastState, currentState) => {
        return shallow(pastState, currentState);
      },
    }
  )
);

export default useStoreWithUndo;
