/* eslint-disable react-hooks/exhaustive-deps */
import { COMMON_STATUS, EVENT_STATUS, STAGE_TYPE, TYPE_SEAT } from "utils/enum";
import { Dropdown } from "primereact/dropdown";
import {
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { ALPHABET, showToast } from "utils/common";
import { forwardRef } from "react";
import { InputText } from "primereact/inputtext";
import EventServices from "services/events";
import { v4 as uuidv4 } from "uuid";
import {
  EditNameType,
  SeatMap,
  SeatsMapDataDoc,
  TextCustomize,
} from "types/seats-map";
import {
  COMMON_STATUS_OPTIONS,
  STAGE_OPTIONS,
  TYPE_SEATS_OPTIONS,
} from "utils/constant";
import { Button } from "primereact/button";
import SeatServices from "services/seats";
import { InputNumber } from "primereact/inputnumber";
import VConfirm from "components/v-confirm";
import { EventDataDoc, SeatOption } from "types/event";
import useStoreWithUndo from "store";
import Selector from "./Selector";
import Actions from "./Actions";
import EditNameDialog from "./EditNameDialog";
import SeatsMap from "./SeatsMap";
import TextCustomizeDialog from "./TextCustomizeDialog";

const Details = (props, ref) => {
  const {
    data,
    isTemplate = false,
    reload,
    toast,
    onCancel,
    setLoading,
  } = props;

  const emptyData: SeatsMapDataDoc = {
    name: "",
    rows: 0,
    cols: 0,
    seats: [],
    type: TYPE_SEAT.NORMAL,
    isTemplate,
    event_id: null,
    status: COMMON_STATUS.ENABLE,
    additionalText: [],
    stage: STAGE_TYPE.FRONT,
  };

  const { seats, setSeats } = useStoreWithUndo();

  const { futureStates, pastStates, clear, undo, redo } =
    useStoreWithUndo.temporal.getState();

  const isDisabledUndo = useMemo(
    () => pastStates.length <= 1,
    [pastStates, seats]
  );
  const isDisabledRedo = useMemo(
    () => !futureStates.length,
    [futureStates, seats]
  );

  const [details, setDetails] = useState<SeatsMapDataDoc>(emptyData);
  const [events, setEvents] = useState<EventDataDoc[]>([]);
  const [eventSelected, setEventSelected] = useState<EventDataDoc | null>(null);
  const [seatOption, setSeatOption] = useState<SeatOption>(null);
  const [seatsSelected, setSeatsSelected] = useState<string[]>([]);
  const [rowsSelected, setRowsSelected] = useState<number[]>([]);
  const [colCustomize, setColCustomize] = useState(0);
  const [showDialogEditName, setShowDialogEditName] = useState(false);
  const [editNameList, setEditNameList] = useState<EditNameType[]>([]);
  const [listTextCustomize, setListTextCustomize] = useState<TextCustomize[]>(
    []
  );

  const [activeTextCustomize, setActiveTextCustomize] = useState(-1);

  const scrollerRef = useRef<HTMLDivElement>(null);
  const refConfirmDisable = useRef(null);
  const refConfirmDeleteRow = useRef(null);
  const refConfirmRegenerate = useRef(null);

  const cloneSeats = useMemo(() => Array.from(seats), [seats]);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  const onInputChange = (value, name) => {
    let _details = { ...details };
    _details[`${name}`] = value;
    setDetails(_details);
  };

  const nestedMap = useCallback(
    (
      cb: (
        rowIndex: number,
        colIndex: number,
        data: SeatMap
      ) => SeatMap | boolean,
      method: string = "map"
    ) => {
      return cloneSeats.map((row, rowIndex) =>
        row[method]((col, colIndex) => cb(rowIndex, colIndex, col))
      );
    },
    [cloneSeats]
  );

  const generateSeatsMap = (rows: number, cols: number) => {
    const generalSeat = {
      color: "white",
      background: "#ccc",
      price: 0,
      isDisabled: false,
      seat_type: "",
      seat_zone: "",
      holding_at: null,
    };
    let seats = [];
    if (details.type === TYPE_SEAT.NORMAL) {
      seats = Array.from({ length: rows }, (_, rowIndex) =>
        Array.from({ length: cols }, (_, colIndex) => ({
          ...generalSeat,
          id: uuidv4(),
          name: `${ALPHABET[rowIndex]}${colIndex + 1}`,
        }))
      );
    } else {
      seats = Array.from({ length: rows }, (_, rowIndex) => {
        let temp = [];
        for (let colIndex = 0; colIndex < cols; colIndex++) {
          const item = {
            ...generalSeat,
            id: uuidv4(),
            name: `${ALPHABET[rowIndex]}${colIndex + 1}`,
          } as SeatMap;
          if ((colIndex + 1) % 2) {
            details?.type === TYPE_SEAT.ODD_EVEN
              ? temp.push(item)
              : temp.unshift(item);
          } else {
            details?.type === TYPE_SEAT.ODD_EVEN
              ? temp.unshift(item)
              : temp.push(item);
          }
        }
        return temp;
      });
    }

    setSeats(seats);
    refConfirmRegenerate?.current?.close();
  };

  const handleSelectRows = (index: number) => {
    const listSelected = rowsSelected.includes(index)
      ? rowsSelected.filter((item) => item !== index)
      : [...rowsSelected, index];

    setRowsSelected(listSelected);

    let seatsSelected = [];

    listSelected.map((item) =>
      cloneSeats[item].map((col) => seatsSelected.push(col.id))
    );
    setSeatsSelected(seatsSelected);
    handleSetEditNameList(seatsSelected);
  };

  const handleChangeSeat = (data: SeatOption) => {
    const cbFnc = (_: number, __: number, col: SeatMap) =>
      seatsSelected?.includes(col.id)
        ? {
            ...col,
            color: data.color,
            background: data?.background,
            price: data?.price,
            seat_type: data.name,
            seat_zone: data.zone,
          }
        : col;

    const newSeats = nestedMap(cbFnc);
    setSeats(newSeats);
  };

  const handleCustomize = () => {
    rowsSelected.forEach((item) => {
      let temp = [];

      const seatsCustomize = Array.from(
        { length: colCustomize },
        (_, index) => {
          const seat = {
            id: uuidv4(),
            name: `${ALPHABET[item]}${index + 1}`,
            color: "white",
            background: "#ccc",
            price: 0,
            isDisabled: false,
            seat_type: "",
            seat_zone: "",
            holding_at: null,
          } as SeatMap;
          if ((index + 1) % 2) {
            details?.type === TYPE_SEAT.ODD_EVEN
              ? temp.push(seat)
              : temp.unshift(seat);
          } else {
            details?.type === TYPE_SEAT.ODD_EVEN
              ? temp.unshift(seat)
              : temp.push(seat);
          }
          return seat;
        }
      );

      cloneSeats[item] =
        details?.type === TYPE_SEAT.NORMAL ? seatsCustomize : temp;
    });

    setSeats(cloneSeats);
    setColCustomize(0);
  };

  const handleDisabled = () => {
    const cbFnc = (_: number, __: number, col: SeatMap) =>
      seatsSelected?.includes(col.id)
        ? {
            ...col,
            isDisabled: true,
          }
        : col;
    const newSeats = nestedMap(cbFnc);
    setSeats(newSeats);
    setSeatsSelected([]);
    setEditNameList([]);
    setRowsSelected([]);
    refConfirmDisable.current.close();
  };

  const handleEditName = (data?: EditNameType[]) => {
    (data ? data : editNameList).map(
      (item) => (cloneSeats[item.rowIndex][item.colIndex] = item.data)
    );
    data && setEditNameList(data);
    setShowDialogEditName(false);
    setSeatsSelected([]);
    setRowsSelected([]);
    return setSeats(cloneSeats);
  };

  const handleDeleteSeat = () => {
    const newSeats = nestedMap(
      (_, __, col) => !seatsSelected.includes(col.id),
      "filter"
    );

    setSeats(newSeats);
    setRowsSelected([]);
    setSeatsSelected([]);
    refConfirmDeleteRow.current.close();
  };

  const handleSetEditNameList = (selected: string[]) => {
    let editNameList = [];
    const cbFnc = (rowIndex: number, colIndex: number, col: SeatMap) => {
      if (selected.includes(col.id)) {
        editNameList.push({
          rowIndex,
          colIndex,
          data: col,
        });
      }
      return col;
    };
    setEditNameList(editNameList);
    nestedMap(cbFnc);
  };

  const handleAddTextCustomize = () => {
    setListTextCustomize((prev) => [
      ...prev,
      {
        x: 0,
        y: 0,
        text: "",
        style: {
          color: "black",
          fontSize: 14,
          fontWeight: 400,
          rotate: 0,
        },
      },
    ]);
    setActiveTextCustomize(listTextCustomize.length);
  };

  const handleChangeTextCustomize = (item: TextCustomize, index?: number) => {
    const cloneList = Array.from(listTextCustomize);
    cloneList[index >= 0 ? index : activeTextCustomize] = item;
    setListTextCustomize(cloneList);
    setActiveTextCustomize(-1);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await SeatServices.updateSeat({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            seats,
            additional_text: listTextCustomize,
          },
        });
      } else {
        await SeatServices.createSeat({
          body: {
            ...details,
            seats,
            additional_text: listTextCustomize,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Seats map saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  useEffect(() => {
    if (data) {
      setDetails(data);
      setSeats(data?.seats || []);
      setListTextCustomize(data?.additional_text || []);
    }

    EventServices.getAll().then((resp) => {
      if (resp?.length) {
        const events = resp?.filter((item) =>
          item?.status === EVENT_STATUS.COMING_SOON && data?.event_id
            ? true
            : !item?.seat_map_id
        );
        setEvents(events);
        if (data?.event_id) {
          setEventSelected(events.find((item) => item._id === data.event_id));
        }
      }
    });
  }, [data]);

  useEffect(() => {
    if (!seatsSelected.length) {
      setRowsSelected([]);
    }
  }, [seatsSelected]);

  useEffect(() => {
    return () => {
      setSeats([]);
      clear();
    };
  }, []);

  return (
    <div className="grid">
      <div className="field col-12 md:col-4">
        <label htmlFor="name">Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onInputChange(e.target.value, "name")}
          required
          autoFocus
        />
      </div>
      {!isTemplate && (
        <div className="field col-12 md:col-4">
          <label htmlFor="name">Events</label>
          <Dropdown
            filter={true}
            disabled={data?.event_id}
            value={details?.event_id}
            options={events?.map((item) => ({
              label: item?.name,
              value: item?._id,
            }))}
            onChange={(e) => {
              onInputChange(e.value, "event_id");
              setEventSelected(events.find((item) => item._id === e.value));
            }}
            optionLabel="label"
            placeholder="Select Event"
            className="w-full"
          />
        </div>
      )}
      <div className="field col-12 md:col-4">
        <label htmlFor="description">Seats Map Type</label>
        <Dropdown
          defaultValue={details.type}
          value={details.type}
          options={TYPE_SEATS_OPTIONS}
          onChange={(e) => onInputChange(e.value, "type")}
          optionLabel="label"
          placeholder="Select type"
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="name">Status</label>
        <Dropdown
          defaultValue={details.status}
          value={details.status}
          options={COMMON_STATUS_OPTIONS}
          onChange={(e) => {
            onInputChange(e.value, "status");
          }}
          optionLabel="label"
          placeholder="Select Status"
          className="w-full"
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="rows">Rows</label>
        <InputNumber
          id="rows"
          value={details.rows}
          onChange={(e) => onInputChange(+e.value, "rows")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="cols">Columns</label>
        <InputNumber
          id="cols"
          value={details.cols}
          onChange={(e) => onInputChange(+e.value, "cols")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="name">Stage</label>
        <Dropdown
          defaultValue={details.stage}
          value={details.stage}
          options={STAGE_OPTIONS}
          onChange={(e) => {
            onInputChange(e.value, "stage");
          }}
          optionLabel="label"
          placeholder="Select Status"
          className="w-full"
        />
      </div>
      <div
        className={
          "col-12 flex flex-column align-items-center justify-content-center w-full mb-4"
        }
      >
        <Button
          onClick={() =>
            seats?.length
              ? refConfirmRegenerate.current.show()
              : generateSeatsMap(details.rows, details.cols)
          }
          disabled={
            !details.rows || !details.cols || (!isTemplate && !eventSelected)
          }
          className="flex align-items-center justify-content-center"
          style={{
            cursor: !details.rows || !details.cols ? "not-allowed" : "pointer",
            width: "200px",
          }}
        >
          Generate Seats Map
        </Button>
      </div>

      {seats?.length > 0 && (
        <div
          className="flex field col-12 w-full flex-column lg:flex-row"
          style={{
            gap: 16,
          }}
        >
          <SeatsMap
            ref={scrollerRef}
            seats={seats}
            stagePosition={details.stage}
            listTextCustomize={listTextCustomize}
            rowsSelected={rowsSelected}
            seatsSelected={seatsSelected}
            handleSelectRows={handleSelectRows}
            setActiveTextCustomize={setActiveTextCustomize}
            handleChangeTextCustomize={handleChangeTextCustomize}
          />

          <Selector
            ref={scrollerRef}
            seats={seats}
            handleSelect={(selected) => {
              handleSetEditNameList(selected);
              setSeatsSelected(selected);
              setRowsSelected([]);
              setSeatOption(null);
            }}
          />

          <div className="flex flex-column flex-1">
            <Actions
              colCustomize={colCustomize}
              editNameList={editNameList}
              isDisabledRedo={isDisabledRedo}
              isDisabledUndo={isDisabledUndo}
              rowsSelected={rowsSelected}
              seatsSelected={seatsSelected}
              refConfirmDeleteRow={refConfirmDeleteRow}
              refConfirmDisable={refConfirmDisable}
              eventSelected={eventSelected}
              seatOption={seatOption}
              isTemplate={isTemplate}
              setSeatOption={setSeatOption}
              handleChangeSeat={handleChangeSeat}
              handleCustomize={handleCustomize}
              setColCustomize={setColCustomize}
              setShowDialogEditName={setShowDialogEditName}
              undo={undo}
              redo={redo}
              handleAddTextCustomize={handleAddTextCustomize}
            />
            <VConfirm
              ref={refConfirmDisable}
              message={"Are you sure you want to unlock this seats"}
              onConfirm={handleDisabled}
            />
            <VConfirm
              ref={refConfirmRegenerate}
              message={"Are you sure you want to regenerate the seats map"}
              onConfirm={() => generateSeatsMap(details.rows, details.cols)}
            />
            <VConfirm
              ref={refConfirmDeleteRow}
              message={"Are you sure you want to delete this row?"}
              onConfirm={handleDeleteSeat}
            />
            <EditNameDialog
              editNameList={editNameList}
              showDialogEditName={showDialogEditName}
              setEditNameList={setEditNameList}
              setShowDialogEditName={setShowDialogEditName}
              handleEditName={handleEditName}
            />
            {activeTextCustomize !== -1 && (
              <TextCustomizeDialog
                activeIndex={activeTextCustomize}
                activeItem={listTextCustomize[activeTextCustomize]}
                onHide={() => setActiveTextCustomize(-1)}
                handleChangeTextCustomize={handleChangeTextCustomize}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default forwardRef(Details);
