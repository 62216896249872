/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useImperativeHandle } from "react";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { ROLE_OPTIONS, permissions } from "utils/constant";
import { MultiSelect } from "primereact/multiselect";
import RoleServices from "services/roles";

const Details = (props, ref) => {
  const { data, role, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    role,
    permissions: [],
  };
  const [details, setDetails] = useState(emptyData);
  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) {
      setDetails(data);
    }
  }, [data]);

  const onChange = (value, name, field = null) => {
    let _details = { ...details };
    if (field) {
      _details[field][`${name}`] = value;
    } else {
      _details[`${name}`] = value;
    }
    setDetails(_details);
  };

  const onDropdownChange = (e, name) => {
    const val = e.value || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await RoleServices.updateRole({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else {
        await RoleServices.createRole({
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Role saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <div className="grid">
      <div className="field col-12">
        <label htmlFor="description">Role</label>
        <Dropdown
          defaultValue={details.role}
          value={details.role}
          options={ROLE_OPTIONS}
          onChange={(e) => onDropdownChange(e, "role")}
          optionLabel="label"
          placeholder="Select role"
        />
      </div>
      <div className="field col-12">
        <label htmlFor="permissions">Permissions</label>
        <MultiSelect
          filter={true}
          value={details.permissions}
          options={permissions.map((item) => ({
            label: item,
            value: item,
          }))}
          onChange={(e) => onChange(e.value, "permissions")}
          optionLabel="label"
          placeholder="Select Permissions"
          display="chip"
          className="w-full"
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
