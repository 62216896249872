import qs from "query-string";
import { request, parseErrorResponse } from "./request";

const create = (body: any) =>
  new Promise((rs, rj) => {
    request()
      .post("admin/themes", body)
      .then((res) => {
        const { data } = res.data;
        if (data) rs(data);
        else {
          rj(new Error("Something went error"));
        }
      })
      .catch(parseErrorResponse)
      .then(rj);
  });

const updateById = (req: { params: { id: string }; body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .put(`admin/themes/` + req.params.id, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getAll = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/themes/`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const search = (query: any) =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/themes/search?${qs.stringify(query)}`)
      .then((res) => {
        const { data } = res;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const ThemesService = {
  create,
  updateById,
  getAll,
  search,
};

export default ThemesService;
