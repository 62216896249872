/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import UserService from "services/users";
import { formatDateValue, formatLocalTime, showToast } from "utils/common";
import VDialog from "components/v-dialog";
import VConfirm from "components/v-confirm";
import { Calendar } from "primereact/calendar";
import debounce from "lodash.debounce";
import { Dropdown, Panel } from "primereact";
import Details from "./Details";
import RoleServices from "services/roles";
import { ROLE_OPTIONS } from "utils/constant";

const STATUS = [
  { name: "Confirmed", value: "confirmed" },
  { name: "Blocked", value: "blocked" },
];

const UserTable = ({
  roles = [],
  isStaff = false,
}: {
  roles?: string[];
  isStaff?: boolean;
}) => {
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState(null);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    start: null,
    end: null,
    status: null,
    roles,
  });

  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [roleOptions, setRoleOptions] = useState([]);

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const refDialogResetPassword = useRef(null);
  const refDialogBlock = useRef(null);

  const refDetail = useRef(null);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const loadData = async () => {
    try {
      const res: any = await UserService.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setTotalRecords(res.totalDocs);
      } else setData([]);
    } catch (error) {
      setData([]);
    }
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  //CREATE OR NEW
  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };
  const editProduct = (data) => {
    setDetail({ ...data });
    refDialogDetail.current.show();
  };
  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };
  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };
  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  // DELETE
  const confirmDelete = (product) => {
    setDetail(product);
    refDialogDelete.current.show();
  };

  const handleDelete = async () => {
    try {
      await UserService.deleteUser({
        params: {
          id: detail._id,
        },
      });
      const _data = data.filter((val) => val._id !== detail._id);
      setData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "User Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const confirmResetPassword = (data) => {
    setDetail(data);
    refDialogResetPassword.current.show();
  };

  const confirmUpdateStatus = (data) => {
    setDetail(data);
    refDialogBlock.current.show();
  };

  const handleResetPassword = async () => {
    try {
      await UserService.resetPasswordByAdmin(detail?._id);
      refDialogResetPassword.current.close();
      setDetail(null);
      showToast(toast, "success", "Reset password success!");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  const handleUpdateStatus = async () => {
    try {
      await UserService.updateUser({
        params: {
          id: detail._id,
        },
        body: {
          status: detail?.status === "confirmed" ? "blocked" : "confirmed",
        },
      });
      refDialogBlock.current.close();
      setDetail(null);
      loadData();
      showToast(toast, "success", "Reset password success!");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  useEffect(() => {
    RoleServices.getAll().then((resp: any) => {
      if (resp?.length) {
        const options = resp.map((item) => ({
          label: ROLE_OPTIONS.find((role) => role.value === item?.role)?.label,
          value: item?.role,
        }));
        setRoleOptions(options);
      }
    });
  }, []);

  //RENDER
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  };
  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning mr-2"
          onClick={() => confirmDelete(rowData)}
        />
        {isStaff && (
          <Button
            icon="pi pi-refresh"
            className="p-button-rounded p-button-sm p-button-success mr-2"
            onClick={() => confirmResetPassword(rowData)}
          />
        )}
        <Button
          icon={`pi ${
            rowData?.status === "confirmed" ? "pi-lock" : "pi-unlock"
          }`}
          className="p-button-rounded p-button-sm p-button-danger mr-2"
          onClick={() => confirmUpdateStatus(rowData)}
        />
      </div>
    );
  };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <div className="grid my-2 align-items-center w-full">
          <span className="block p-input-icon-left col-12 md:col-4">
            <i className="ml-1 pi pi-search" />
            <InputText
              className="w-full"
              type="search"
              onInput={(e: any) => onSearch("search", e.target.value)}
              placeholder="Search..."
            />
          </span>{" "}
          <div className="col-12 md:col-4">
            <Dropdown
              value={globalFilter.roles}
              options={roleOptions}
              onChange={(e) => onSearch("roles", e.value)}
              optionLabel="label"
              placeholder="Select Role"
              className="w-full"
              showClear
            />
          </div>
          <div className="col-12 md:col-4">
            <Dropdown
              value={globalFilter.status}
              options={STATUS}
              onChange={(e: any) => onSearch("status", e.value)}
              optionLabel="name"
              placeholder="Status"
              showClear
              className={"w-full"}
            />
          </div>
          <div className="col-12 md:col-6">
            <Calendar
              className="w-full"
              id="time24"
              placeholder="Start Date"
              value={globalFilter.start}
              onChange={(e: any) => onSearch("start", e.value)}
              maxDate={new Date()}
              showTime
              showSeconds
              showButtonBar
            />{" "}
          </div>
          <div className="col-12 md:col-6">
            <Calendar
              className="w-full"
              id="time24"
              placeholder="End Date"
              value={globalFilter.end}
              onChange={(e: any) => onSearch("end", e.value)}
              maxDate={new Date()}
              showTime
              showSeconds
              showButtonBar
            />
          </div>
        </div>
      </div>
    </Panel>
  );

  return (
    <div>
      <Toast ref={toast} />
      <Toolbar
        className="mb-4"
        left={leftToolbarTemplate}
        right={rightToolbarTemplate}
      ></Toolbar>
      <DataTable
        ref={dt}
        value={data}
        header={header}
        emptyMessage="No data found."
        dataKey="id"
        size="small"
        scrollDirection="both"
        className="datatable-responsive"
        scrollable
        showGridlines
        lazy
        //panigate
        paginator
        first={lazyParams.first}
        rows={lazyParams.rows}
        totalRecords={totalRecords}
        rowsPerPageOptions={[10, 20, 50, 100]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="{first} - {last} of {totalRecords}"
        onPage={onPage}
        //sort
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        onSort={onSort}
      >
        <Column
          body={actionBodyTemplate}
          style={{ flexGrow: 1, flexBasis: "300px" }}
        ></Column>
        <Column
          field="email"
          header="Email"
          style={{ flexGrow: 1, flexBasis: "350px" }}
        ></Column>
        <Column
          field="role"
          header="Role"
          style={{ flexGrow: 1, flexBasis: "200px" }}
          body={(rowData) => (
            <p>
              {ROLE_OPTIONS.find((role) => role.value === rowData?.role)?.label}
            </p>
          )}
        ></Column>
        <Column
          field="full_name"
          header="Full Name"
          style={{ flexGrow: 1, flexBasis: "300px" }}
        ></Column>
        <Column
          style={{ flexGrow: 1, flexBasis: "200px" }}
          field="phone"
          header="Phone Number"
        ></Column>
        <Column
          style={{ flexGrow: 1, flexBasis: "200px" }}
          field="gender"
          header="Gender"
        ></Column>
        <Column
          style={{ flexGrow: 1, flexBasis: "200px" }}
          field="birthday"
          header="Birthday"
          body={(rowData) =>
            rowData?.birthday &&
            new Date(
              formatDateValue(rowData.birthday, "minus")
            )?.toLocaleDateString()
          }
        ></Column>
        <Column
          style={{ flexGrow: 1, flexBasis: "160px" }}
          field="status"
          header="Status"
          body={(rowData) => (
            <span className={`v-badge status-${rowData.status.toLowerCase()}`}>
              {rowData.status}
            </span>
          )}
        ></Column>
        <Column
          style={{ flexGrow: 1, flexBasis: "200px" }}
          field="createdAt"
          header="Created At"
          sortable
          body={(rowData) => formatLocalTime(rowData.createdAt)}
        ></Column>
      </DataTable>

      <VDialog
        ref={refDialogDetail}
        header="Details"
        onSubmit={onSumitDialogDetail}
      >
        <Details
          ref={refDetail}
          data={detail}
          toast={toast}
          roleOptions={roleOptions}
          reload={loadData}
          setLoading={setLoadingSaveDetail}
          onCancel={onCancelDialogDetail}
        />
      </VDialog>

      <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
      <VConfirm
        ref={refDialogResetPassword}
        message={`Are you sure you want to reset password for user?`}
        onConfirm={handleResetPassword}
      />
      <VConfirm
        ref={refDialogBlock}
        message={
          detail?.status === "blocked"
            ? `Are you sure you want to unlock this user?`
            : `Are you sure you want to lock this user?`
        }
        onConfirm={handleUpdateStatus}
      />
    </div>
  );
};

export default UserTable;
