import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const create = (body: any) =>
  new Promise((rs, rj) => {
    request()
      .post("admin/topics", body)
      .then((res) => {
        const { data } = res.data;
        if (data) rs(data);
        else {
          rj(new Error("Something went error"));
        }
      })
      .catch(parseErrorResponse)
      .then(rj);
  });

const deleteById = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .delete("admin/topics/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else {
          reject(new Error("Something went error"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateById = (req: { params: { id: string }; body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .put(`admin/topics/` + req.params.id, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const search = (req: any) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/topics/search?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getAll = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/topics/`)
      .then((res) => {
        const { data } = res;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const TopicService = {
  create,
  deleteById,
  updateById,
  search,
  getAll,
};

export default TopicService;
