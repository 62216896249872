/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useImperativeHandle, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { COMMON_STATUS, MENU_TYPE } from "utils/enum";
import MenusServices from "services/menus";
import { TreeSelect } from "primereact/treeselect";
import { InputNumber } from "primereact/inputnumber";
import VUpload from "components/v-upload";
import ReactQuillEditor from "react-quill";
import ReactQuill from "react-quill";
import { Panel } from "primereact/panel";
import { MenuDoc, SocialOption } from "types/menus";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";

const Details = (props, ref) => {
  const {
    data,
    reload,
    toast,
    onCancel,
    setLoading,
    treeMenus,
    loadTreeMenus,
  } = props;

  const emptyData: MenuDoc = {
    key: "",
    name: "",
    slogan: "",
    status: COMMON_STATUS.ENABLE,
    type: "",
    parent_id: null,
    order: 0,
    logo: "",
    url: "",
    phone: "",
    email: "",
    location: "",
    content: "",
    social: [],
    iconEmail: "",
    iconPhone: "",
    DMCA: "",
  };

  const [details, setDetails] = useState<MenuDoc>(emptyData);
  const [optionSocial, setOptionSocial] = useState<SocialOption[]>([]);

  const editorRef = useRef<ReactQuill>(null);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    if (data) {
      setDetails(data);
      setOptionSocial(data?.social || []);
    }
  }, [data]);

  useEffect(() => {
    details.type !== MENU_TYPE.INFO && loadTreeMenus();
  }, [details.type]);

  const onChange = (fieldName, value) => {
    setDetails((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleAddSocial = () => {
    setOptionSocial((prev) => [
      ...prev,
      {
        link: "",
        icon: "",
      },
    ]);
  };
  const handleDeleteSocial = (index: number) => {
    const cloneSocial = Array.from(optionSocial);
    setOptionSocial(cloneSocial.filter((_, i) => i !== index));
  };
  const onChangeSocial = (value: string, index: number, filed: string) => {
    const cloneSocial = Array.from(optionSocial);
    cloneSocial[index][filed] = value;
    setOptionSocial(cloneSocial);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await MenusServices.updateMenu({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            social: optionSocial,
          },
        });
      } else {
        await MenusServices.createMenu({
          body: {
            ...details,
            social: optionSocial,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Menu saved!");
      loadTreeMenus();
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <>
      <div className="grid">
        <div className="field col-12 md:col-6">
          <label htmlFor="description">Type</label>
          <Dropdown
            defaultValue={MENU_TYPE.INFO}
            value={details.type}
            options={Object.keys(MENU_TYPE).map((k) => ({
              label: k,
              value: MENU_TYPE[k],
            }))}
            optionLabel="label"
            optionValue="value"
            onChange={(e) => onChange("type", e.value)}
          />
        </div>
        <div className="field col-12 md:col-6">
          <label htmlFor="name">Name</label>
          <InputText
            id="name"
            value={details.name}
            onChange={(e) => onChange("name", e.target.value)}
            required
            autoFocus
          />
        </div>
        {details.type === MENU_TYPE.INFO && (
          <div className="grid field w-full align-items-center mx-0">
            <div className="field col-12 md:col-6">
              <label htmlFor="name">Email</label>
              <InputText
                id="email"
                value={details.email}
                onChange={(e) => onChange("email", e.target.value)}
                required
                autoFocus
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="name">Phone</label>
              <InputText
                id="phone"
                value={details.phone}
                onChange={(e) => onChange("phone", e.target.value)}
                required
                autoFocus
              />
            </div>
            {/* <div className="field col-12">
              <label htmlFor="name">Address</label>
              <InputText
                id="location"
                value={details.location}
                onChange={(e) => onChange("location", e.target.value)}
                required
                autoFocus
              />
            </div> */}
            <div className="field col-12">
              <label htmlFor="name">Slogan</label>
              <InputText
                id="slogan"
                value={details.slogan}
                onChange={(e) => onChange("slogan", e.target.value)}
                required
                autoFocus
              />
            </div>
            <Panel
              header={"Social"}
              toggleable
              collapsed={true}
              className="col-12"
            >
              {optionSocial?.map((social: any, index: number) => (
                <div key={index} className="grid w-full">
                  <div className="field col-12">
                    <label htmlFor="name">Link</label>
                    <InputText
                      id="link"
                      value={social?.link}
                      onChange={(e) =>
                        onChangeSocial(e.target.value, index, "link")
                      }
                      required
                      autoFocus
                    />
                  </div>
                  <div className="field col-10 my-4">
                    <label htmlFor="logo" className="text-center w-full">
                      Icon
                    </label>
                    <InputText
                      id="icon"
                      value={social?.icon}
                      onChange={(e) =>
                        onChangeSocial(e.target.value, index, "icon")
                      }
                      required
                      autoFocus
                    />
                  </div>

                  <div className="field flex flex-column align-items-center justify-center col-2 my-auto">
                    <Button
                      onClick={() => handleDeleteSocial(index)}
                      icon="pi pi-times"
                      className="p-button-rounded p-button-danger"
                      aria-label="Search"
                    />
                  </div>
                </div>
              ))}
              <div className="field col-12">
                <Button
                  onClick={handleAddSocial}
                  icon="bx bxs-add-to-queue"
                  className="p-button-rounded p-button-success mb-3 flex align-items-center"
                  style={{
                    width: "fit-content",
                    columnGap: 4,
                  }}
                  aria-label="Search"
                >
                  Add
                </Button>
              </div>
            </Panel>
            <div className="field col-12 my-4">
              <label htmlFor="logo" className="text-center w-full">
                Logo
              </label>
              <VUpload
                urlFile={details.logo}
                setUrlFile={(url) => onChange("logo", url)}
                fileType="image"
              />
            </div>
            <div className="field col-6 my-4">
              <label htmlFor="iconEmail" className="text-center w-full">
                Icon Email
              </label>
              <VUpload
                urlFile={details.iconEmail}
                setUrlFile={(url) => onChange("iconEmail", url)}
                fileType="image"
              />
            </div>
            <div className="field col-6 my-4">
              <label htmlFor="iconPhone" className="text-center w-full">
                Icon Phone
              </label>
              <VUpload
                urlFile={details.iconPhone}
                setUrlFile={(url) => onChange("iconPhone", url)}
                fileType="image"
              />
            </div>
          </div>
        )}
        {details.type === MENU_TYPE.NAVIGATE && (
          <div className="grid field w-full align-items-center mx-0">
            <div className="field col-12 md:col-6">
              <label htmlFor="">Order</label>
              <InputNumber
                id="order"
                value={details.order}
                onChange={(e) => onChange("order", e.value)}
                required
                autoFocus
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="description">Status</label>
              <Dropdown
                defaultValue={COMMON_STATUS.ENABLE}
                value={details.status}
                options={Object.keys(COMMON_STATUS).map((k) => ({
                  label: k,
                  value: COMMON_STATUS[k],
                }))}
                optionLabel="label"
                optionValue="value"
                onChange={(e) => onChange("status", e.value)}
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="parent">Parent</label>
              <TreeSelect
                inputId="treeselect"
                filter
                selectionMode="single"
                placeholder="Select Parent Menu"
                value={details.parent_id}
                onChange={(e) => {
                  onChange("parent_id", e.value);
                }}
                options={treeMenus.map((item) => ({
                  label: item?.label,
                  key: item?.id,
                }))}
              ></TreeSelect>
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="url">Url</label>
              <InputText
                id="url"
                value={details.url}
                onChange={(e) => onChange("url", e.target.value)}
                placeholder="/cam-xuc-bat-tan"
                required
              />
            </div>
          </div>
        )}
        {details.type === MENU_TYPE.INTRODUCE && (
          <div className="w-full">
            <div className="field col-12">
              <label htmlFor="content">Content</label>
              <ReactQuillEditor
                ref={editorRef}
                value={details.content}
                onChange={(e) => {
                  onChange("content", e);
                }}
                style={{ height: "500px", marginBottom: "40px" }}
                modules={{
                  toolbar: [
                    [{ header: [1, 2, 3, false] }],
                    ["bold", "italic", "underline"],
                    [{ color: [] }, { background: [] }, { size: [] }],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { list: "check" },
                    ],
                    [{ script: "sub" }, { script: "super" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    ["direction", { align: [] }],
                    ["link", "image", "video", "formula"],
                    ["clean"],
                  ],
                }}
              />
            </div>
            <div className="field col-12">
              <label htmlFor="content">DMCA (HTML)</label>
              <InputTextarea
                value={details.DMCA}
                onChange={(e) => {
                  onChange("DMCA", e.target.value);
                }}
                cols={5}
              ></InputTextarea>
            </div>
          </div>
        )}

        {/* <div className="field col-12 md:col-6">
          <label htmlFor="description">Position</label>
          <Dropdown
            value={details.position}
            defaultValue={MENU_POSITION.HEADER}
            options={Object.keys(MENU_POSITION).map((k) => ({
              label: k,
              value: MENU_POSITION[k],
            }))}
            optionLabel="label"
            optionValue="value"
            onChange={(e) => onChange("position", e.value)}
          />
        </div> */}
      </div>
    </>
  );
};

export default forwardRef(Details);
