/* eslint-disable react-hooks/exhaustive-deps */
import VUpload from "components/v-upload";
import { MasterContext } from "contexts/MasterContext";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import UserService from "services/users";
import { showToast } from "utils/common";

const Row = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={`flex flex-column w-full ${className}`}
      style={{
        rowGap: 12,
      }}
    >
      {children}
    </div>
  );
};

const AccountPage = () => {
  const { auth, setAuth } = useContext(MasterContext);
  const toast = useRef(null);

  const [accountForm, setAccountForm] = useState({
    avatar: "",
    full_name: "",
    email: "",
    password: "",
    phone: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const onChangeFormField = (value: string | number, fieldName: string) => {
    setAccountForm((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleUpdateUser = () => {
    const { avatar, full_name, email, phone, password } = accountForm;
    setIsLoading(true);
    UserService.updateUser({
      params: {
        id: auth.id,
      },
      body: {
        avatar,
        full_name,
        email,
        phone,
        ...(auth.password !== password ? { password } : {}),
      },
    })
      .then(() => {
        UserService.getUserById({
          params: {
            id: auth.id,
          },
        }).then((resp) => setAuth(resp.user));
        showToast(toast, "success", "Updated Successfully.");
      })
      .finally(() => setIsLoading(false));
  };

  const handleSetFormValue = useCallback(
    () =>
      setAccountForm({
        avatar: auth?.avatar || "",
        full_name: auth.full_name,
        email: auth.email,
        password: auth.password,
        phone: auth.phone || "",
      }),
    [auth]
  );

  useEffect(() => {
    if (auth) {
      handleSetFormValue();
    }
  }, [auth, handleSetFormValue]);

  return (
    <Row className="card">
      <Toast ref={toast} />
      <Row>
        <label htmlFor="">Avatar</label>
        <VUpload
          urlFile={accountForm.avatar}
          setUrlFile={(url) => onChangeFormField(url, "avatar")}
          fileType="image"
          imageStyle={{
            objectFit: "cover",
            borderRadius: 8,
          }}
        />
      </Row>

      <Row>
        <label htmlFor="">Full Name</label>
        <InputText
          value={accountForm.full_name}
          onChange={(e) => onChangeFormField(e.target.value, "full_name")}
          placeholder="Full Name"
        />
      </Row>
      <Row>
        <label htmlFor="">Email</label>
        <InputText
          value={accountForm.email}
          onChange={(e) => onChangeFormField(e.target.value, "email")}
          placeholder="Email"
          disabled={true}
        />
      </Row>
      <Row>
        <label htmlFor="">Phone Number</label>
        <InputText
          value={accountForm.phone}
          onChange={(e) => onChangeFormField(e.target.value, "phone")}
          placeholder="Phone Number"
          type="number"
        />
      </Row>
      <Row>
        <label htmlFor="">Password</label>
        <InputText
          value={accountForm.password}
          type="password"
          onChange={(e) => onChangeFormField(e.target.value, "password")}
          placeholder="Password"
        />
      </Row>
      <div
        className="flex align-items-center justify-content-end w-full mt-4"
        style={{
          columnGap: 12,
        }}
      >
        <Button
          className="p-button-sm p-button-danger"
          onClick={handleSetFormValue}
        >
          Cancel
        </Button>
        <Button
          className="p-button-sm p-button-success"
          loading={isLoading}
          onClick={handleUpdateUser}
        >
          Save
        </Button>
      </div>
    </Row>
  );
};

export default AccountPage;
