import { request } from "./request";

const getSettings = async () => {
  try {
    const response = await request().get(`admin/settings`);
    return response?.data?.data;
  } catch (error) {
    const errorMess = error?.response?.data?.message;
    throw errorMess;
  }
};

const getSettingsByType = async ({ type }: { type: string }) => {
  try {
    const response = await request().get(`admin/settings/get-by-type/${type}`);
    return response?.data?.data;
  } catch (error) {
    const errorMess = error?.response?.data?.message;
    throw errorMess;
  }
};

const updateSetting = async ({ body }: { body: any }) => {
  try {
    const response = await request().put(`admin/settings/update-by-type`, body);
    return response?.data?.data;
  } catch (error) {
    const errorMess = error?.response?.data?.message;
    throw errorMess;
  }
};

const createSetting = async ({ body }: { body: any }) => {
  try {
    const response = await request().post(`admin/settings/create`, body);
    return response?.data?.data;
  } catch (error) {
    const errorMess = error?.response?.data?.message;
    throw errorMess;
  }
};

const getLocations = async () => {
  try {
    const response = await request().get(`admin/locations`);
    return response?.data?.data;
  } catch (error) {
    const errorMess = error?.response?.data?.message;
    throw errorMess;
  }
};

const SettingService = {
  createSetting,
  getSettings,
  updateSetting,
  getSettingsByType,
  getLocations,
};

export default SettingService;
