import React from "react";

export const AppFooter = (props: any) => {
  return (
    <div className="layout-footer">
      <img
        src={
          props.layoutColorMode === "light"
            ? "/images/s-talent-logo.svg"
            : "/images/s-talent-logo.svg"
        }
        alt="Logo"
        height="20"
        className="mr-2"
      />
    </div>
  );
};
