/* eslint-disable react-hooks/exhaustive-deps */
import VUpload from "components/v-upload";
import { MasterContext } from "contexts/MasterContext";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import SettingService from "services/settings";
import { showToast } from "utils/common";
import { SETTING_TYPES } from "utils/enum";

const Row = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={`flex flex-column w-full ${className}`}
      style={{
        rowGap: 12,
      }}
    >
      {children}
    </div>
  );
};

const Onepay = () => {
  const { auth } = useContext(MasterContext);
  const toast = useRef(null);

  const [settingData, setSettingData] = useState<any>(null);
  const [systemForm, setSystemForm] = useState({
    SECURE_SECRET: "",
    vpc_Merchant: "",
    vpc_AccessCode: "",
    vpc_ReturnURL: "",
    vpc_Version: "",
    vpc_Command: "",
    vpc_URL: 0,
    AgainLink: "",
    vpc_User: "",
    vpc_Password: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const onChangeFormField = (value: string | number, fieldName: string) => {
    setSystemForm((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleSettingSystem = () => {
    setIsLoading(true);
    const actionFnc = settingData
      ? SettingService.updateSetting
      : SettingService.createSetting;
    actionFnc({
      body: {
        type: SETTING_TYPES.ONEPAY,
        value: systemForm,
      },
    })
      .then(() => {
        handleGetSettingSystem();
        showToast(toast, "success", "Updated Successfully.");
      })
      .finally(() => setIsLoading(false));
  };

  const handleSetFormValue = useCallback(
    (data: any) => data && setSystemForm(data?.value),
    [auth]
  );

  const handleGetSettingSystem = () => {
    SettingService.getSettingsByType({
      type: SETTING_TYPES.ONEPAY,
    }).then((resp) => {
      setSettingData(resp.setting);
      handleSetFormValue(resp.setting);
    });
  };

  useEffect(() => {
    if (auth) {
      handleGetSettingSystem();
    }
  }, [auth, handleSetFormValue]);

  return (
    <Row className="card">
      <Toast ref={toast} />
      <Row>
        <label htmlFor="">Title</label>
        <InputText
          value={systemForm.SECURE_SECRET}
          onChange={(e) => onChangeFormField(e.target.value, "SECURE_SECRET")}
          placeholder="SECURE_SECRET"
        />
      </Row>
      <Row>
        <label htmlFor="">vpc_Merchant</label>
        <InputText
          value={systemForm.vpc_Merchant}
          onChange={(e) => onChangeFormField(e.target.value, "vpc_Merchant")}
          placeholder="vpc_Merchant"
        />
      </Row>
      <Row>
        <label htmlFor="">vpc_AccessCode</label>
        <InputText
          value={systemForm.vpc_AccessCode}
          onChange={(e) => onChangeFormField(e.target.value, "vpc_AccessCode")}
          placeholder="vpc_AccessCode"
        />
      </Row>

      <Row>
        <label htmlFor="">vpc_ReturnURL</label>
        <InputText
          value={systemForm.vpc_ReturnURL}
          onChange={(e) => onChangeFormField(e.target.value, "vpc_ReturnURL")}
          placeholder="0"
          prefix="%"
        />
      </Row>

      <Row>
        <label htmlFor="">vpc_Version</label>
        <InputText
          value={systemForm.vpc_Version}
          onChange={(e) => onChangeFormField(e.target.value, "vpc_Version")}
          placeholder="0"
          prefix="%"
        />
      </Row>
      <Row>
        <label htmlFor="">vpc_Command</label>
        <InputText
          value={systemForm.vpc_Command}
          onChange={(e) => onChangeFormField(e.target.value, "vpc_Command")}
          placeholder="0"
          prefix="%"
        />
      </Row>
      <Row>
        <label htmlFor="">vpc_URL</label>
        <InputText
          value={systemForm.vpc_URL}
          onChange={(e) => onChangeFormField(e.target.value, "vpc_URL")}
          placeholder="0"
          prefix="%"
        />
      </Row>

      <Row>
        <label htmlFor="">AgainLink</label>
        <InputText
          value={systemForm.AgainLink}
          onChange={(e) => onChangeFormField(e.target.value, "AgainLink")}
          placeholder="0"
          prefix="%"
        />
      </Row>

      <Row>
        <label htmlFor="">vpc_User</label>
        <InputText
          value={systemForm.vpc_User}
          onChange={(e) => onChangeFormField(e.target.value, "vpc_User")}
          placeholder="0"
          prefix="%"
        />
      </Row>

      <Row>
        <label htmlFor="">vpc_Password</label>
        <InputText
          value={systemForm.vpc_Password}
          onChange={(e) => onChangeFormField(e.target.value, "vpc_Password")}
          placeholder="0"
          prefix="%"
        />
      </Row>

      <div
        className="flex align-items-center justify-content-end w-full mt-4"
        style={{
          columnGap: 12,
        }}
      >
        <Button
          className="p-button-sm p-button-danger"
          onClick={() => handleSetFormValue(settingData)}
        >
          Cancel
        </Button>
        <Button
          className="p-button-sm p-button-success"
          loading={isLoading}
          onClick={handleSettingSystem}
        >
          Save
        </Button>
      </div>
    </Row>
  );
};

export default Onepay;
