import { useState, createContext } from "react";
import SettingService from "services/settings";
import UserService from "services/users";

type MasterContext = {
  auth?: any;
  users?: any;
  getUsers?: any;
  setAuth?: any;
  groups?: any;
  locations?: any;
  getLocations?: any;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MasterContext = createContext<MasterContext>({} as MasterContext);

export const MasterProvider = (props: any) => {
  const [users, setUsers] = useState([]);
  const [auth, setAuth] = useState(null);
  const [locations, setLocations] = useState<any>([]);

  const getLocations = () => {
    SettingService.getLocations().then((resp: any) => {
      if (resp && resp.length) {
        setLocations(resp);
      }
    });
  };

  const getUsers = () => {
    UserService.getUsers({
      query: {
        search: "",
      },
    }).then((res: any) => {
      if (res && res.users) setUsers(res.users);
    });
  };

  return (
    <MasterContext.Provider
      value={{
        auth,
        users,
        locations,
        getUsers,
        setAuth,
        getLocations,
      }}
    >
      {/* <Toast ref={toast} /> */}
      {props.children}
    </MasterContext.Provider>
  );
};
