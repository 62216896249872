import React, { useState, useCallback } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import "./moveable.css";
import { TextCustomize } from "types/seats-map";

interface Props {
  textCustomize: TextCustomize;
  index: number;
  setActiveTextCustomize: (index: number) => void;
  handleChangeTextCustomize: (item: TextCustomize, index?: number) => void;
}

interface Position {
  x: number;
  y: number;
}

const Moveable: React.FC<Props> = ({
  textCustomize,
  index,
  setActiveTextCustomize,
  handleChangeTextCustomize,
}) => {
  const { text, x, y, style } = textCustomize;

  const [deltaPosition, setDeltaPosition] = useState<Position>({
    x,
    y,
  });

  const handleDrag = useCallback((e: DraggableEvent, data: DraggableData) => {
    e.stopPropagation();
    setDeltaPosition((prevPosition) => ({
      x: prevPosition.x + data.deltaX,
      y: prevPosition.y + data.deltaY,
    }));
  }, []);

  const onStop = useCallback(() => {
    handleChangeTextCustomize(
      {
        ...textCustomize,
        x: deltaPosition.x,
        y: deltaPosition.y,
      },
      index
    );
  }, [deltaPosition, textCustomize, handleChangeTextCustomize, index]);

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: 50,
        width: "fit-content",
        display: "flex"
      }}
    >
      <Draggable
        position={{
          x: deltaPosition.x,
          y: deltaPosition.y,
        }}
        onDrag={handleDrag}
        onStop={onStop}
        // {...dragHandlers}
      >
        <div
          className="moveable-box"
          onDoubleClick={() => setActiveTextCustomize(index)}
        >
          <p
            style={{
              fontSize: style.fontSize,
              fontWeight: style.fontWeight,
              color: style.color,
              transform: `rotate(${style.rotate}deg)`,
              whiteSpace: "nowrap"
            }}
          >
            {text}
          </p>
        </div>
      </Draggable>
    </div>
  );
};

export default Moveable;
