import { SeatsMapData, SeatsMapDataDoc } from "types/seats-map";
import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const search = (req: any): Promise<SeatsMapData> =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`admin/seats/search?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createSeat = (data: { body: any }) =>
  new Promise((rs, rj) => {
    request()
      .post("admin/seats", data.body)
      .then((res) => {
        const { data } = res.data;
        if (data) rs(data);
        else {
          rj(new Error("Something went error"));
        }
      })
      .catch(parseErrorResponse)
      .then(rj);
  });

const updateSeat = (req: { params: { id: string }; body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .put("admin/seats/" + req.params.id, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getAll = (): Promise<SeatsMapDataDoc[]> =>
  new Promise((resolve, reject) => {
    request()
      .get(`admin/seats/`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const deleteSeat = (req: { params: { id: string } }) =>
  new Promise((resolve, reject) => {
    request()
      .delete("admin/seats/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else {
          reject(new Error("Something went error"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const SeatServices = {
  search,
  createSeat,
  updateSeat,
  getAll,
  deleteSeat,
};

export default SeatServices;
