/* eslint-disable react-hooks/exhaustive-deps */
import VUpload from "components/v-upload";
import { Button } from "primereact/button";
import { ColorPicker } from "primereact/colorpicker";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Panel } from "primereact/panel";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import BlogServices from "services/blogs";
import { BlogDoc, HashtagOption } from "types/blogs";
import { showToast } from "utils/common";
import { COMMON_STATUS_OPTIONS } from "utils/constant";
import { COMMON_STATUS } from "utils/enum";
import ReactQuillEditor from "react-quill";
import ReactQuill from "react-quill";
import TopicService from "services/topics";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData = {
    title: "",
    description: "",
    date: null,
    tags: [],
    thumbnail: "",
    status: COMMON_STATUS.ENABLE,
    content: "",
    topic_id: null,
    slug: "",
  };
  const [details, setDetails] = useState<BlogDoc>(emptyData);
  const [hashtag, SetHashtag] = useState<HashtagOption[]>([]);
  const [optionTopic, setOptionTopic] = useState([]);

  const editorRef = useRef<ReactQuill>(null);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  const onInputChange = (value, name) => {
    setDetails((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleAddHashtag = () => {
    SetHashtag((prev) => [
      ...prev,
      {
        name: "",
        background: "#ffffff",
        color: "#000000",
      },
    ]);
  };

  const handleDeleteHashtag = (index: number) => {
    const cloneHashtag = Array.from(hashtag);
    SetHashtag(cloneHashtag.filter((_, i) => i !== index));
  };

  const onChangeHashtag = (value: string, index: number, filed: string) => {
    const cloneHashtag = Array.from(hashtag);
    cloneHashtag[index][filed] = value;
    SetHashtag(cloneHashtag);
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await BlogServices.updateById({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            tags: hashtag,
          },
        });
      } else {
        await BlogServices.create({
          body: {
            ...details,
            tags: hashtag,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Blog saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const getOptionTopics = async () => {
    TopicService.getAll().then((res: any) => {
      setOptionTopic(res.data);
    });
  };

  useEffect(() => {
    if (data) {
      setDetails(data);
      SetHashtag(data?.tags);
    }
    getOptionTopics();
  }, [data]);

  return (
    <div className="grid">
      <div className="field col-12">
        <label htmlFor="title">Slug</label>
        <InputText
          id="slub"
          value={details.slug}
          onChange={(e) => onInputChange(e.target.value, "slug")}
          required
          autoFocus
        />
      </div>
      <div className="field col-6">
        <label htmlFor="name">Status</label>
        <Dropdown
          defaultValue={details.status}
          value={details.status}
          options={COMMON_STATUS_OPTIONS}
          onChange={(e) => {
            onInputChange(e.value, "status");
          }}
          optionLabel="label"
          placeholder="Select Status"
          className="w-full"
        />
      </div>
      <div className="field col-6">
        <label htmlFor="name">Topic</label>
        <Dropdown
          defaultValue={details.topic_id}
          value={details.topic_id}
          options={optionTopic.map((item: any) => ({
            label: item.name,
            value: item._id,
          }))}
          onChange={(e) => {
            onInputChange(e.value, "topic_id");
          }}
          optionLabel="label"
          placeholder="Select Topic"
          className="w-full"
        />
      </div>
      <div className="field col-12">
        <VUpload
          isObject={true}
          objectFile={details.thumbnail}
          setObjectFile={(o) => onInputChange(o, "thumbnail")}
          fileType="image"
        />
      </div>
      <div className="field col-12">
        <label htmlFor="title">Title</label>
        <InputText
          id="title"
          value={details.title}
          onChange={(e) => onInputChange(e.target.value, "title")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12">
        <label htmlFor="description">Description</label>
        <InputTextarea
          id="description"
          value={details.description}
          onChange={(e) => onInputChange(e.target.value, "description")}
        />
      </div>

      <Panel header={"Hashtag"} toggleable collapsed={true} className="col-12">
        {hashtag.map((hashtag, index) => (
          <div key={index} className="grid w-full">
            <div className="field col-12 md:col-3">
              <label htmlFor="name">Name</label>
              <InputText
                id="name"
                value={hashtag?.name}
                onChange={(e) => onChangeHashtag(e.target.value, index, "name")}
                required
                autoFocus
              />
            </div>
            <div className="field flex flex-column col-12 md:col-4">
              <label>Background Color</label>
              <div className="flex align-items-center">
                <InputText
                  id="color"
                  value={hashtag?.background}
                  onChange={(e) =>
                    onChangeHashtag(e.target.value, index, "background")
                  }
                  required
                  autoFocus
                  className="mr-3"
                />
                <ColorPicker
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: 8,
                  }}
                  value={hashtag?.background}
                  onChange={(e) =>
                    onChangeHashtag(
                      `#${e.target.value as string}`,
                      index,
                      "background"
                    )
                  }
                />
              </div>
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="name">Color</label>
              <div className="flex align-items-center">
                <InputText
                  id="color"
                  value={hashtag?.color}
                  onChange={(e) =>
                    onChangeHashtag(e.target.value, index, "color")
                  }
                  className="mr-3"
                  required
                  autoFocus
                />
                <ColorPicker
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: 8,
                  }}
                  value={hashtag?.color}
                  onChange={(e) =>
                    onChangeHashtag(
                      `#${e.target.value as string}`,
                      index,
                      "color"
                    )
                  }
                />
              </div>
            </div>
            <div className="field flex flex-column align-items-center justify-center col-12 md:col-1 my-auto">
              <Button
                onClick={() => handleDeleteHashtag(index)}
                icon="pi pi-times"
                className="p-button-rounded p-button-danger"
                aria-label="Search"
              />
            </div>
          </div>
        ))}
        <div className="field col-12">
          <Button
            onClick={handleAddHashtag}
            icon="bx bxs-add-to-queue"
            className="p-button-rounded p-button-success mb-3 flex align-items-center"
            style={{
              width: "fit-content",
              columnGap: 4,
            }}
            aria-label="Search"
          >
            Add Hashtag Option
          </Button>
        </div>
      </Panel>
      <div className="field col-12">
        <label htmlFor="content">Content</label>
        <ReactQuillEditor
          ref={editorRef}
          value={details.content}
          onChange={(e) => {
            onInputChange(e, "content");
          }}
          style={{ height: "500px", marginBottom: "40px" }}
          modules={{
            toolbar: [
              [{ header: [1, 2, 3, false] }],
              ["bold", "italic", "underline"],
              [{ color: [] }, { background: [] }, { size: [] }],
              [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              ["direction", { align: [] }],
              ["link", "image", "video", "formula"],
              ["clean"],
            ],
          }}
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
