/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";

import { TabView, TabPanel } from "primereact/tabview";
import UserTable from "./components/UserTable";
import { ROLE } from "utils/constant";

const View = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className="grid View-demo">
      <div className="col-12">
        <div className="card">
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            <TabPanel header="All Users">
              <UserTable />
            </TabPanel>
            <TabPanel header="Customer">
              <UserTable roles={[ROLE.CUSTOMER]} />
            </TabPanel>
            <TabPanel header="Staff">
              <UserTable
                roles={[
                  ROLE.ACCOUNTANT,
                  ROLE.CONTENT_MARKETING,
                  ROLE.CENSOR_STAFF,
                  ROLE.SALE_STAFF,
                ]}
                isStaff={true}
              />
            </TabPanel>
            <TabPanel header="Agency">
              <UserTable roles={[ROLE.AGENCY]} />
            </TabPanel>

            <TabPanel header="Admin">
              <UserTable roles={[ROLE.ADMIN]} />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
